import {Component, Input, OnInit} from '@angular/core';

//SERVICES
import { FunctionsControllerService } from '../../../services/generic/functions-controller.service';
import { GenericService } from '../../../services/generic/generic.service';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent implements OnInit {

  @Input() structure = null;
  @Input() param : any = null;

  public idTable = null;
  public idRow = null;
  public idTableIndex = null;

  constructor(public functionsControllerService: FunctionsControllerService,
    public genericService: GenericService) {
    }

  ngOnInit(): void {
    if(this.param !== null) {
      let respPar = this.genericService.fillTableDataFromParam(this.param, this.idTable, this.idRow, this.idTableIndex);
      this.idTable = respPar['idTable'];
      this.idRow = respPar['idRow'];
      this.idTableIndex = respPar['idTableIndex'];
    }
  }

  onClickEvent(event) {
    this.structure['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if (this.structure && this.structure["id_function"]) {
      //this.functionsControllerService.getFunction(eventTrigger['structure']);
      if(this.idTable == null && this.idRow == null) {
        let finished = {done: true};
        this.functionsControllerService.getFunction(this.structure, null, finished);
      } else {
        this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex});
        this.functionsControllerService.getFunction(this.structure, this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
      }
    }
  }
}
