import { Component, Input, OnInit } from '@angular/core';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import mermaid from 'mermaid';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { add } from 'date-fns';
import { text } from 'express';

@Component({
  selector: 'app-organization-chart-custom',
  templateUrl: './organization-chart-custom.component.html',
  styleUrls: ['./organization-chart-custom.component.scss']
})
export class OrganizationChartCustomComponent implements OnInit {

  mermaidDefinition: string;
  public loaded = false;
  idEmpresa: number;

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;


  constructor(private endpointService: EndpointService, public authService: AuthService, public functionsService: FunctionsService, public functionsControllerService: FunctionsControllerService, public genericService: GenericService) { }

  ngOnInit(): void {
    this.idEmpresa = this.authService.getIdCompany();
    this.loadStructure();
  }

  loadStructure() {
    this.loaded = false;
    this.endpointService.getOrganizationChart(this.idEmpresa).subscribe((resp) => {
      if (resp["response"] != null && resp["response"] != "") {
        let data = resp["response"];

        // let diagram = "%%{init: {'theme': 'base', 'themeVariables': { 'darkMode' : true, 'fontSize': '12px', 'primaryColor': '" + this.authService.primary_color + 60 + "' , 'primaryTextColor': '#000' , 'secondaryTextColor': '#000', 'secondaryColor': '" + this.authService.tertiary_color + 99 + "' , 'tertiaryColor': '" + this.authService.secondary_color + 99 + "' , 'primaryBorderColor': '" + this.authService.primary_color + 80 + "' , 'secondaryBorderColor': '" + this.authService.tertiary_color + 80 + "'}}}%%\n";
        // diagram += 'erDiagram\n';
        
        let diagram = `graph TD\n\n`;

        let departamentosQuery = data['departamentos'];
        let equiposQuery = data['equipos'];
        let rolesQuery = data['roles'];
        let usuariosQuery = data['usuarios'];

        console.log('Departamentos:', departamentosQuery, 'Equipos:', equiposQuery, 'Roles:', rolesQuery, 'Usuarios:', usuariosQuery);

        departamentosQuery.forEach(row => {
          diagram += addEquipos(row, equiposQuery, rolesQuery, usuariosQuery);
        });

        console.log('Diagram:', diagram);
        
        this.mermaidDefinition = diagram;
        this.loaded = true;
        setTimeout(() => {
          this.renderMermaid();
        });
      }
    });
  }

  renderMermaid(): void {
    mermaid.initialize({ startOnLoad: false });
    const element = document.querySelector('#mermaid-diagram');
    if (element) {
      mermaid.init(undefined, element as HTMLElement);
      setTimeout(() => {
        this.addClickEventListeners();
      }, 1000);
    }
  }
  
  addClickEventListeners(): void {
    const nodes = document.querySelectorAll('#mermaid-diagram g');
    nodes.forEach(node => {
      const tableName = this.extractTableNameFromNode(node);
      node.addEventListener('click', () => this.handleNodeClick(tableName));
    });
  }

  extractTableNameFromNode(node: Element): string {
    const textElement = node.querySelector('.er.entityLabel');
    if (textElement) {
      return textElement.textContent.trim();
    } else {
      return ''; // Manejar el caso donde no se encuentra el texto esperado
    }
  }

  handleNodeClick(node): void {
    console.log('Node clicked:', node);
    let idFounded = false;
    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    }
    let output = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
    if (output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']] && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output']) {
      for (let j in output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output']) {
        if (output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['id_db'] == 3 && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['bd_table'] == "db_schemas_tables" && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['bd_field'] == "bd_table") {
          output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['value'] = node;
          idFounded = true;
          break;
        }
      }
    }
    if (!idFounded) {
      let obj = { id_db: 3, bd_table: "db_schemas_tables", bd_field: "bd_table", value: node }
      output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'].push(obj);
    }
  
    this.structure[this.index]['no_save_params'] = true;
    this.functionsControllerService.getFunction(this.structure[this.index]);
    
  }

}

function addResponsableRol(id_usuario_responsable_rol, usuariosQuery) {
  const usuario = usuariosQuery.find(u => u.id === id_usuario_responsable_rol);
  if (!usuario) return "";
  return `Rol_${cleanText(usuario.nombre_rol)} --> Responsable_${cleanText(usuario.nombre_usuario)}\n`;
}

function addResponsableDepartamento(departamento, usuariosQuery) {
  const usuario = usuariosQuery.find(u => u.id === departamento.id_usuario_responsable_departamento);
  if (!usuario) return "";
  return `Departamento_${cleanText(usuario.nombre_departamento)} --> Responsable_${cleanText(usuario.nombre_usuario)}\n`;
}

function addEquipos(departamento, equiposQuery, rolesQuery, usuariosQuery) {
  let equipos = "";
  equiposQuery.forEach(row => {
    equipos += `Departamento_${cleanText(departamento.nombre_departamento)} --> Equipo_${cleanText(row.nombre_equipo)}\n`;
    equipos += addResponsableDepartamento(departamento, usuariosQuery);
    if (row.id_departamento_equipo === departamento.id_departamento && row.nombre_equipo) {
      equipos += `Equipo_${cleanText(row.nombre_equipo)} --> Usuario_${cleanText(row.nombre_usuario)}\n`;
      equipos += addRoles(row.id_equipo, rolesQuery, usuariosQuery);
    }
  });
  return equipos;
}

function addRoles(id_equipo, rolesQuery, usuariosQuery) {
  let roles = "";
  rolesQuery.forEach((row: { id_equipo: any; id_rol: any; id_usuario_responsable_rol: any; nombre_rol: any; }) => {
    if (row.id_equipo === id_equipo) {
      roles += `    Equipo_${cleanText(row.nombre_rol)} --> Rol_${cleanText(row.nombre_rol)}\n`;
      roles += addResponsableRol(row.id_usuario_responsable_rol, usuariosQuery);
      roles += addUsuarios(row.id_rol, row.nombre_rol, usuariosQuery);
    }
  });
  return roles;
}

function addUsuarios(id_rol, nombre_rol, usuariosQuery) {
  let usuarios = "";
  usuariosQuery.forEach(row => {
    if (row.id_rol === id_rol && row.nombre_usuario) {
      usuarios += `    Rol_${cleanText(nombre_rol)} --> Usuario_${cleanText(row.nombre_usuario)}\n`;
    }
  });
  return usuarios;
}

function cleanText(text: string | null | undefined) {
  return (text || "").replace(/[\n\r]+/g, ' ').replace(/"/g, "'").replace(/ñ/g, "n").replace(/ /g, '_');
}