import { Component, OnInit, Input, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import {GenericService} from "../../../services/generic/generic.service";
import {FunctionsService} from "../../../services/generic/functions.service";
import { MatMenuTrigger } from '@angular/material/menu';
import { RoutingService } from 'app/services/routing/routing.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() structure : any;
  @Input() index : number;
  @Input() param : any = null;
  @Input() label : string;
  @ViewChild('buttonTrigger') buttonTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger', {static: true}) menuTrigger: MatMenuTrigger;

  
  @HostListener('mouseover')
  mouseover() {
    this.genericService.toggleMenuVisibility('over');
  }
  
  @HostListener('mouseout')
  mouseout() {
    this.genericService.toggleMenuVisibility('out');
  }
  
  public menuOpen: any;
  public previousTrigger: any;
  public lastMenuHasSubmenu: boolean;
  public loaded = false;
  public idTable = null;
  public idRow = null;
  public idTableIndex = null;

  constructor(public genericService: GenericService,
              private functionsService: FunctionsService,
              private functionsControllerService: FunctionsControllerService,
              public conditionsClientService: ConditionsClientService,
              private routingService: RoutingService) { }

  ngOnInit(): void {
    this.menuOpen = null;
    if(this.param !== null) {
      let respPar = this.genericService.fillTableDataFromParam(this.param, this.idTable, this.idRow, this.idTableIndex);
      this.idTable = respPar['idTable'];
      this.idRow = respPar['idRow'];
      this.idTableIndex = respPar['idTableIndex'];
    }
    this.loaded = true;
  }

  onClickEvent(id, event) {
    setTimeout(() => {
      this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      this.genericService.lastMenuClick = this.structure[this.index]['child'][id]['id_functional_area'];
      if(this.idTable == null && this.idRow == null) {
        let data = {done: false};
        this.functionsControllerService.getFunction(this.structure[this.index]['child'][id], data);
      } else {
        this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex});
        this.functionsControllerService.getFunction(this.structure[this.index]['child'][id], this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
      }
    });
  }

  onClickMenu(key, event) {
    this.genericService.lastMenuClick = this.structure[this.index]['child'][key]['id_functional_area'];
    //En funcion de su id le asigno su funcion a realizar
    if(this.structure[this.index]['child'][key]['id_function']){
      this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      //te function
      if(this.idTable == null && this.idRow == null) {
        this.functionsControllerService.getFunction(this.structure[this.index]['child'][key]);
      } else {
        this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex});
        this.functionsControllerService.getFunction(this.structure[this.index]['child'][key], this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
      }
      this.genericService.toggleMenuVisibility('out');
    } else{
      //no te function desplego només el submenu
    }
  }

  openMenuTrigger(trigger, menu){
    if(trigger != null){
      if(this.menuOpen !== null){
        if(this.menuOpen['level'] == menu['level'] && this.menuOpen['child'] != undefined) this.previousTrigger.closeMenu();
      }
      if(menu['child'] != undefined) trigger.openMenu();
      this.lastMenuHasSubmenu = true;
    } else {
      if(this.lastMenuHasSubmenu){
        this.previousTrigger.closeMenu();
      }
      this.lastMenuHasSubmenu = false;
    }

    this.menuOpen = menu;
    this.previousTrigger = trigger;
  }
}
