
<mat-icon [cdkCopyToClipboard]="element['id_element']" class="debbugingIdsIcon" *ngIf="this.authService.checkUserIsDeveloping == 1 && !element['creatingMode'] && this.genericService.devModeGeneric" (click)="this.genericService.consoleLogMenu(element); $event.stopPropagation()">info</mat-icon>
<span [ngClass]="'generic-menu-type-' + element['id_type']">
    <span [ngClass]="{'text-tab-generic': true, 'has-children': element['children'] && element['children'].length > 0, 'has-route': element['route'] !== undefined &&  element['route'] != null}" (click)="clickMenu($event, element)">
        <span *ngIf="element.id_type != 3" [ngClass]="element['route'] && element['route'] == routingService.urlWithoutHashtagClean ? 'highlight text-tab-generic-label' : 'text-tab-generic-label'" style="font-weight: 600;">
            <span *ngIf="element.icon" class="material-icons icon-tab-generic">{{ element.icon }}</span>
            <span *ngIf="element['children'] && element['children'].length > 0 && !this.element['show']" class="material-icons expand-icon" (click)="changeHideELement(); $event.stopPropagation()">keyboard_arrow_down</span>
            <span *ngIf="element['children'] && element['children'].length > 0 && this.element['show']" class="material-icons expand-icon" (click)="changeHideELement(); $event.stopPropagation()">keyboard_arrow_up</span>
            <span>{{element.label}}</span>
        </span>
        <ng-container *ngIf="element.id_type == 3 && element.id_functional_area > 0 && genericService.paramControlVariables[element.id_functional_area] && genericService.paramControlVariables[element.id_functional_area]['params']">
            <ng-container *ngFor="let par of genericService.paramControlVariables[element.id_functional_area]['params'] | keyvalue : genericService.returnZero">
                <span [ngClass]="genericService.paramControlVariables[element.id_functional_area]?.route_internal == routingService.urlWithoutHashtagClean && genericService.paramControlVariables[element.id_functional_area]['indexParam'] == par.key ? 'highlight text-tab-generic-label' : 'text-tab-generic-label'" *ngIf="par.key && par.key != undefined && par.key != 'undefined'" (click)="swipeTabIndex(par.key, element.id_functional_area, genericService.paramControlVariables[element.id_functional_area].route_internal, $event)">
                    <span *ngIf="genericService.paramControlVariables[element.id_functional_area]['indexParam'] != par.key" class="material-icons close-tab-icon" (click)="this.deleteIndexTabGeneric(element.id_functional_area, par.key); $event.stopPropagation()">cancel</span>
                    <span>
                        <span *ngIf="!genericService.paramControlVariables[element.id_functional_area]['params'][par.key]['labelWindowParam']">#{{+par.key + 1}}</span>
                        <span *ngIf="genericService.paramControlVariables[element.id_functional_area]['params'][par.key]['labelWindowParam']" matTooltip="{{genericService.paramControlVariables[element.id_functional_area]['params'][par.key]['labelWindowParam']}}">{{genericService.paramControlVariables[element.id_functional_area]['params'][par.key]['labelWindowParam']}}</span>
                    </span>
                </span>
            </ng-container>
        </ng-container>
    </span>
    
    <ng-container *ngIf="element['children'] && element['children'].length > 0">
        <app-menu-generic-elements class="menus-childrens" [elements]="element['children']" [show]="element['show']"></app-menu-generic-elements>
    </ng-container>
</span>