import { Component, Input, OnInit } from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import { FunctionsService } from "../../../services/generic/functions.service";
import { AuthService } from "../../../services/auth/auth.service";
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;

  public oldId = null;
  public idTable = null;
  public idRow = null;
  public idTableIndex = null;

  constructor(public functionsService: FunctionsService,
    private functionsControllerService: FunctionsControllerService,
    public conditionsClientService: ConditionsClientService,
    public genericService: GenericService,
    public authService: AuthService) { }

  ngOnInit(): void {
    if (!this.genericService.staticHTML) {
      if (this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
        let param = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
        if (param['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['lastTableIndex']) {
          this.oldId = param['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['lastTableIndex']
        }
      }
    }

    if(this.param !== null) {
      let respPar = this.genericService.fillTableDataFromParam(this.param, this.idTable, this.idRow, this.idTableIndex);
      this.idTable = respPar['idTable'];
      this.idRow = respPar['idRow'];
      this.idTableIndex = respPar['idTableIndex'];
    }
  }

  scrollTo(id: string) {
    let el = document.getElementById(id.slice(1));
    //el.scrollTop = el.scrollHeight;
    this.genericService.scroll(id.slice(1));
  }

  public onClickItem(item: any, event) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    this.genericService.lastListClick[this.structure[this.index['id_functional_parent']]] = this.index;
    if (!this.genericService.staticHTML) {
      if (this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
        this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['lastTableIndex'] = item['id_functional_area'];
      }
    }
    this.oldId = item['id_functional_area']
    if (this.idTable == null && this.idRow == null) {
      this.functionsControllerService.getFunction(item);
    } else {
      this.genericService.tablesActualRows.push({ "id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex });
      this.functionsControllerService.getFunction(item, this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
    }
  }

}
