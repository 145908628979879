import { Component, OnInit, Input } from '@angular/core';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-timer-count',
  templateUrl: './timer-count.component.html',
  styleUrls: ['./timer-count.component.scss']
})
export class TimerCountComponent implements OnInit {
  @Input() structure: any;
  @Input() index: number;

  initTime: any;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  daysStr: string = '00';
  hoursStr: string = '00';
  minutesStr: string = '00';
  secondsStr: string = '00';
  counter = null;
  type: string = '';

  constructor(
    public functionsService: FunctionsService,
    public genericService: GenericService,
  ) { }

  ngOnInit(): void {
    this.type = this.structure[this.index]['type'];
    this.updateTime();
  }
  
  ngOnDestroy(): void {
    if(this.counter) clearInterval(this.counter);
  }
  
  updateTime(): void {
    if(this.type === 'counter') {
      this.updateTimeCounter();
      this.counter = setInterval(() => { 
        this.updateTimeCounter(); 
      }, 60000);
    }
    else if(this.type === 'countdown') {
      this.updateTimeCountdown();
      this.counter = setInterval(() => { 
        this.updateTimeCountdown();
        if (this.days <= 0 && this.hours <= 0 && this.minutes <= 0 && this.seconds <= 0) {
          clearInterval(this.counter);
        }        
      }, 1000);
    }

  }

  formatDate() {
    // Separa la data i l'hora
    const [datePart, timePart] = this.initTime.split(", ");

    // Divideix dia, mes i any
    const [day, month, year] = datePart.split("-").map(Number);

    // Divideix hora i minuts
    const [hours, minutes] = timePart.split(":").map(Number);

    // Crea l'objecte Date (mesos són zero-based: gener = 0, febrer = 1, etc.)
    this.initTime = new Date(year, month - 1, day, hours, minutes);
  }

  updateTimeCounter():void {    
    this.initTime = this.structure[this.index]['label'];
    this.formatDate();

    let now = new Date();
    let diff = now.getTime() - this.initTime.getTime();
    this.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((diff % (1000 * 60)) / 1000);

    this.daysStr = this.formatTwoDigits(this.days);
    this.hoursStr = this.formatTwoDigits(this.hours);
    this.minutesStr = this.formatTwoDigits(this.minutes);
    this.secondsStr = this.formatTwoDigits(this.seconds);    
  }

  updateTimeCountdown():void {
    this.initTime = this.structure[this.index]['label'];
    this.formatDate();

    let now = new Date();
    let diff = this.initTime.getTime() - now.getTime();
    this.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((diff % (1000 * 60)) / 1000);

    this.daysStr = this.formatTwoDigits(this.days);
    this.hoursStr = this.formatTwoDigits(this.hours);
    this.minutesStr = this.formatTwoDigits(this.minutes);
    this.secondsStr = this.formatTwoDigits(this.seconds);
  }

  formatTwoDigits(n: number): string {
    return n.toString().padStart(2, '0');
  }

}
