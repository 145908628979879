import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef, AfterContentChecked, OnDestroy, AfterViewInit } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { of } from "rxjs";
//import { Pipe, PipeTransform } from '@angular/core';

//SERVICES
import { EndpointService } from "../../../services/generic/endpoint.service";
import { GenericService } from "../../../services/generic/generic.service";
import { Observable } from "rxjs";
import { UntypedFormControl, Validators } from "@angular/forms";
import { map, startWith, filter, debounceTime, switchMap } from "rxjs/operators";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatRadioChange } from "@angular/material/radio";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { Dispatcher } from "app/services/dispatcher";
import { FunctionsService } from "../../../services/generic/functions.service";
import { FunctionsControllerService } from "../../../services/generic/functions-controller.service";
import { ConditionsClientService } from "app/services-client/conditions-client.service";

@Component({
  selector: "app-selection",
  templateUrl: "./selection.component.html",
  styleUrls: ["./selection.component.css"],
})
export class SelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() structure: any;
  @Input() index: number;
  genericForm: any;

  public options: any;
  public idQuery: number
  public optionsAuto: string[] = [];
  public optionsAutoMap: Map<string, number>;
  public optionsAutoMapGroup: Map<string, number>;
  public optionsAutoMapColors: Map<string, number>;
  public chipsList: string[] = [];
  public bdField: string;
  public filteredOptions: Observable<string[]>;
  // Joan
  public filteredOptionsLength: number = 0;
  public filteredOptionsNotShown: number = 0; // Per si es necessités junt o per comptes de filteredOptionsLength
  
  public myControl = new UntypedFormControl();
  public multiple: boolean;
  public selectable: boolean;
  public removable: boolean;
  public separatorKeysCodes: number[];
  //public showSuffix = false;
  //private ogdata;
  public value: any;
  public valueInit: any;
  public isEmptyValue: boolean;
  public loadedValue;
  public loaded = false;
  private subscribeArray = [];
  public inputValueLabel: string;
  public numCharactersSearch = 0;
  public hasMultiLevel = false;
  public hasColor = false;
  public arrayGroups = [];
  public lastQueryLenght = -1;
  public arrayResultsInitial = [];

  @Output() notifyOthers = new EventEmitter<{}>();

  @ViewChild("chipInput") chipInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  constructor(
    public genericService: GenericService,
    public conditionsClientService: ConditionsClientService,
    private endpointService: EndpointService,
    protected dispatcher: Dispatcher,
    private functionsService: FunctionsService,
    private functionsControllerService: FunctionsControllerService
  ) {}


  ngOnInit(): void {
    let sbscr = this.dispatcher.subscribe(this);
    this.subscribeArray.push(sbscr);
    if(this.structure[this.index]['tmp_value'] === null || this.structure[this.index]['tmp_value'] === undefined) this.structure[this.index]['tmp_value'] = '';
    this.loadedValue = this.structure[this.index]['tmp_value'];
    //this.option.value = this.structure[this.index]['tmp_value'];
    this.bdField = this.structure[this.index]["bd_field"];
    this.genericForm = this.structure[this.index]["form"];
    this.multiple = this.structure[this.index]["multiple"] == 1;
    let results = this.genericService.selectOptions[this.structure[this.index]['id_query']];
    this.chooseType();
    /* Joan
    if(results && results !== undefined) {
      if(results.length > 300) {
        this.numCharactersSearch = 0;
      } else if(results.length > 100) {
        this.numCharactersSearch = 0;
      }
    }
    */

    this.genericService.initFormChangeField(this.structure[this.index]);
    this.typeofInitialValue();

    if(this.multiple && !Array.isArray(this.structure[this.index]["tmp_value"])){
      this.value = [this.structure[this.index]["tmp_value"]];
      if(results && results !== undefined && !results.includes(this.structure[this.index]['tmp_value'])) this.value = null;
      this.isEmptyValue = false;
    } else {
      this.value = this.structure[this.index]["tmp_value"];
      if(this.value === '') this.value = null; 
      this.isEmptyValue = false;
    }

    this.selectable = true;
    this.removable = true;
    this.separatorKeysCodes = [ENTER, COMMA];
    this.optionsAutoMap = new Map();
    this.optionsAutoMapGroup = new Map();
    this.optionsAutoMapColors = new Map();

    if (this.structure[this.index]["type"] == "radio" || this.structure[this.index]["type"] == "toggle") {
      if (this.structure[this.index]["id_functional_status_general"] === 5) {
        this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValidators([
          Validators.required,
        ]);
      }
      this.structure[this.index].invalid = false;
    }
    if(this.multiple) {
      if(this.structure[this.index]['tmp_value'] !== undefined && this.structure[this.index]['tmp_value'] !== null) this.structure[this.index].originalValue = JSON.parse(JSON.stringify(this.structure[this.index]['tmp_value']))
      if(!Array.isArray(this.structure[this.index]["originalValue"])) this.structure[this.index]["originalValue"] = [this.structure[this.index]["originalValue"]]
      if (this.structure[this.index]['tmp_value'] === undefined && this.structure[this.index]["type"] == "autocomplete") {
        this.structure[this.index]['tmp_value'] = [];
      }
      if (!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = [];
    }

    if (this.value !== undefined && this.value !== null) {
      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value,
      });
    }
    let susbcr = this.genericService.structureUpdated.subscribe(
      (strc) => {
        for(let key in strc){
          const elem = this.genericService.findElementWithIdLoop(this.genericService.getAllStructures(1), this.structure[this.index]['id_functional_area']);
          if(elem){
            this.value = this.genericService.cloneVariable(elem['child']['tmp_value']);
            if(this.value === '') this.value = null;
            if (this.value) {
              this.genericForm.patchValue({
                [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
              });
            }

            if(this.structure[this.index]["type"] == "autocomplete") {
              this.myControl.setValue(Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v == this.value).map(([k]) => k));
              //this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v == this.value).map(([k]) => k)
            }
          }
        }
      }
    );
    this.subscribeArray.push(susbcr);

    let parent = this.structure[this.index]['id_functional_parent_initial'];

    if(this.genericForm && this.genericForm['controls'] && this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.bdField]) {
      let vcsbcr = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.bdField].valueChanges.subscribe((newToogleValue) => {
        this.typeofInitialValue();
        this.valueInit = this.functionsService.cloneVariable(this.value);
        this.value = this.genericService.cloneVariable(newToogleValue);
  
        let v = null;
        let type = '';
        if(this.structure[this.index]["type"] == "autocomplete") {
          if(this.multiple && Array.isArray(newToogleValue)) {
            v = [];
            for(let i in newToogleValue) {
              this.optionsAutoMap.forEach((key, value) => {
                if(key == newToogleValue[i] || value == newToogleValue[i]) v.push(key);
              });
            }
          } else {
            this.optionsAutoMap.forEach((key, value) => {
              if(key == newToogleValue || value == newToogleValue) v = key;
            });
          }
          type = 'autocomplete_';
        } else if(this.structure[this.index]["id_functional_type"] != 15) {
          if(newToogleValue !== undefined) v = newToogleValue;
          else v = undefined;
          type = 'selection_';
        }
        let valueInit = "";
        if(this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] !== undefined && this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'] !== undefined) valueInit = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
        else valueInit = this.valueInit;


        if((v || v == 0) && v !== "" && (valueInit !== v)) {
          let vold = null, vnew = null, voriginal = null;
          if((this.genericService.selectOptions[this.idQuery]) && type != 'autocomplete_') {
            let arrayResults = [];
            arrayResults = this.genericService.selectOptions[this.idQuery];
            if(this.structure[this.index]["type"] === 'radio') {
              type = "radio_";
            }
            if(this.structure[this.index]["type"] === 'toggle') {
              type = "toggle_";
            }
  
            if(!this.multiple) {
              for(let o of arrayResults){
                if (o['value'] === valueInit) vold = o['text'];
                if (o['value'] === v && v !== valueInit) vnew = o['text'];
                if (vold != null && vnew != null) break;
              }
            } else {
              vold = "";
              vnew = "";
              let oldCount = valueInit !== null ? valueInit.length- 1 : 0;
              let newCount = v.length - 1;
              for(let o of this.genericService.selectOptions[this.idQuery]){
                if(valueInit !== null) {
                  for (let g of valueInit) {
                    if (o['value'] === g) {
                      vold = vold + o['text'];
                      if (oldCount > 0) vold = vold + ", ";
                      oldCount--;
                    }
                  }
                }
                for(let t of v) {
                  if (o['value'] === t) {
                    vnew = vnew + o['text'];
                    if(newCount > 0) vnew = vnew + ", ";
                    newCount--;
                  }
                }
              }
            }
          } else {
            type = 'autocomplete_';
            voriginal = valueInit;
            if(!this.multiple) {
              vnew = newToogleValue;
              this.optionsAutoMap.forEach((key, value) => {
                if(key == vnew || value == vnew){
                  vnew = value;
                }
                if(key == voriginal || value == voriginal){
                  vold = value;
                }
              });
            } else {
              let vnuevo = this.genericService.cloneVariable(this.structure[this.index]['tmp_value']);
              vold = "";
              vnew = "";
              let oldCount = voriginal !== null && voriginal.length ? voriginal.length- 1 : 0;
              let newCount = vnuevo.length - 1;
              this.optionsAutoMap.forEach((key, value) => {
                if(voriginal !== null) {   
                  for(let vo of voriginal) {
                    if(key == vo || value == vo){
                      vold = vold + value;
                      if (oldCount > 0) vold = vold + ", ";
                      oldCount--;
                    }
                  }             
                }
                for(let t of vnuevo) {
                  if(key == t || value == t) {
                    vnew = vnew + value;
                    if(newCount > 0) vnew = vnew + ", ";
                    newCount--;
                  }
                }
              });
            }
          }
          if(vnew === vold){
            if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
            if(this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']]) {
              if(this.structure[this.index]["type"] === "radio") type = "radio_";
              if(this.structure[this.index]["type"] === "toggle") type = "toggle_";
              setTimeout(() => {
                delete this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']];
              }, 500);
            }
          } else {
            let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
            if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
            this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, v, this.structure[this.index]['label']);
          }
        } else if(valueInit === v) {
          if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
          if(this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']]){
            if(this.structure[this.index]["type"] === "radio") type = "radio_";
            if(this.structure[this.index]["type"] === "toggle") type = "toggle_";
            setTimeout(() => {
              delete this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']];
            }, 500);
          }
        } else if(this.genericService.selectOptions[this.idQuery]) {
          this.clear(this.bdField, 1);
        }
  
        if(this.structure[this.index]["type"] != "autocomplete") {
          this.structure[this.index]["tmp_value"] = newToogleValue;
        } else {
          if(!this.multiple) {
            let valueNewToggle = newToogleValue;
            this.optionsAutoMap.forEach((key, value) => {
              if(key == valueNewToggle || value == valueNewToggle){
                valueNewToggle = key;
              }
            });
            this.structure[this.index]["tmp_value"] = valueNewToggle;
          }
        }
        this.isEmptyValue = false;
      });
      this.subscribeArray.push(vcsbcr);
  
      let vcfcsbcr = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']].valueChanges.subscribe((newValue) => {
        this.updateDataValidity(newValue);
      })
      this.subscribeArray.push(vcfcsbcr);
    }

    this.getDisplayedOptions();

    /* Joan
    this.filteredOptions = this.myControl.valueChanges.pipe(
      filter((value: string) => value.length > (this.numCharactersSearch - 1)),
      debounceTime(200),
      startWith(""),
      map((chip: string | null) => {
        if(chip) {
          const filterValue = this.normalize(chip.toLowerCase());  
          let result = this.optionsAuto.filter(option =>
            this.normalize(option).toLowerCase().includes(filterValue)
          );
          return result;
        } else {
          return this.optionsAuto.slice()
        }
      })
    );
    */
    this.filteredOptions = this.myControl.valueChanges.pipe(
      //filter((value: string) => value.length > (this.numCharactersSearch - 1)),
      //debounceTime(200),
      startWith(""),
      map(chip => {
        //console.log("map: chip=[" + chip + "] optionsAuto.length=" + this.optionsAuto.length);
        const filterValue = chip == null ? '' : (this.normalize(chip) || '').toLowerCase();
        let result = this.optionsAuto.filter(option => {
          if(option === undefined || option === null) option = '';
          const normalizedOption = this.normalize(option);
          return normalizedOption.toLowerCase().includes(filterValue);
        });
         //console.log("result del filtre:" + result.length);
        this.filteredOptionsLength = result.length;
        //this.filteredOptionsNotShown = (this.filteredOptionsLength > 200 ? this.filteredOptionsLength - 200 : 0);
        return this.filteredOptionsLength == 0
               ? result.slice()
               : (this.filteredOptionsLength <= 200 ? result : result.slice(0, 199));
      })
    );      

    if(this.structure[this.index]["type"] == "autocomplete") {
      this.myControl.setValue(Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v == this.value).map(([k]) => k));
      //this.genericForm.value[this.index]["bd_field"] = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v == this.value).map(([k]) => k)
      this.checkTmpValueMultiple();
    }

    //this.myControl.setValidators(Validators.required)
    this.structure[this.index]['tmp_value'] = this.loadedValue;
    /*if(this.structure[this.index]["tmp_value"] == null) {
      this.myControl.setValue('');
    }*/


    this.loaded = true;

    // Inicialitzem els valors originals
    this.genericService.setOriginalInputsValues(this.structure, this.index);

    let optionsSubs = this.genericService.selectOptionsChangeEvent.subscribe(() => {
      if(this.genericService.selectOptions[this.idQuery]) this.getDisplayedOptions();
      this.chooseType();
    })
    this.subscribeArray.push(optionsSubs);

    let formFieldsUpdateSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.value = this.structure[this.index]['tmp_value'];
    });
    this.subscribeArray.push(formFieldsUpdateSubscription);
    this.arrayResultsInitial = this.functionsService.cloneVariable(this.genericService.selectOptions[this.idQuery]);
  }

  ngAfterViewInit() {
    this.updateDataValidity(this.structure[this.index]["tmp_value"]);
    this.functionsControllerService.getFunctionFormField(this.structure[this.index], true);
  }

  private updateDataValidity(v, init = false) {
    if(this.structure[this.index]["type"] == 'autocomplete' && !init) v = this.optionsAutoMap.get(v);
    let data_validity_value = v;
    if (v === undefined || v === null) {
      if (this.structure[this.index]['tmp_value'] === undefined || this.structure[this.index]['tmp_value'] == '' || this.structure[this.index]['tmp_value'] === null) data_validity_value = -1;
      else data_validity_value = this.structure[this.index]['tmp_value'];
    } else if (v == -999) {
      data_validity_value = -1;
    }
    this.genericService.updateStatus(data_validity_value, this.structure[this.index])
  }

  chooseEmptySelection(bool){
    //this.showSuffix = bool;
    this.isEmptyValue = !bool;
  }

  searchGetCall(term: string) {
    if (term === '') {
      return of([]);
    }
    const arrayTerm = term.split(' ');
    return this.endpointService.getLocations(arrayTerm, false);
  }

  ngOnDestroy() {
    this.genericService.deleteRequiredInFrom(this.structure[this.index]);
    this.subscribeArray.forEach((subscription) => {
      if(subscription !== undefined) subscription.unsubscribe()
    });
  }

  getDisplayedOptions(): void {
    let filter = {
      bd_table: this.structure[this.index]["bd_table"],
      bd_field: this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]["bd_field"],
      id_db: this.structure[this.index]["id_db"],
      id_functional_area: this.structure[this.index]["id_functional_area"],
      window: this.structure[this.index]["window"],
    };
    let filter_data = this.genericService.findFormControl(filter);
    this.idQuery = +this.structure[this.index]["id_query"];
    
    if (this.idQuery !== 0) {
      let data = this.genericService.selectOptions[this.idQuery];
      let indexToCheck = !this.multiple && this.structure[this.index]['form_field_empty'] == 1 && this.structure[this.index]['id_functional_status_general'] !== 5 && this.structure[this.index]['id_functional_status_general'] !== 3 ? 1 : 0;
      this.hasMultiLevel = (this.genericService.selectOptions[this.idQuery] !== undefined && this.genericService.selectOptions[this.idQuery][indexToCheck] !== undefined && this.genericService.selectOptions[this.idQuery][indexToCheck]['to_group'] !== undefined);
      this.hasColor = (this.genericService.selectOptions[this.idQuery] !== undefined && this.genericService.selectOptions[this.idQuery][indexToCheck] !== undefined && this.genericService.selectOptions[this.idQuery][indexToCheck]['color'] !== undefined);
      if(this.genericService.selectOptions[this.idQuery] && this.genericService.selectOptions[this.idQuery].length) this.lastQueryLenght = this.functionsService.cloneVariable(this.genericService.selectOptions[this.idQuery].length);

      if(this.hasMultiLevel) {
        for(let i in data) {
          const tg = data[i].to_group;
          if (!this.arrayGroups.includes(tg)) {
            this.arrayGroups.push(tg);
          }
        }
      }

      if (this.structure[this.index]["primary_key"] == 1 && data && data !== undefined) {
        data = data.filter((elem) => {
          if(filter_data && filter_data.length) {
            for (let i = 0; i < filter_data.length; ++i) {
              if(filter_data[i] && filter_data[i].length) {
                for (let j = 0; j < filter_data[i].length; ++j) {
                  if (elem["value"] == filter_data[i][j]) return false;
                  if (Array.isArray(filter_data[i][j])) {
                    if (elem["value"] == filter_data[i][j][0]) return false;
                  }
                }
              }
            }
          }

          return true;
        });
      }
      
      if (this.structure[this.index]["type"] === "autocomplete") {
        if(data){
          this.optionsAuto = [];
          this.optionsAutoMap = new Map;
          if(this.hasMultiLevel) this.optionsAutoMapGroup = new Map;
          if(this.hasColor) this.optionsAutoMapColors = new Map;
          data.forEach((d) => {
            if(d.text != ""){
              // eliminem la empty option de les que es mostren
              this.optionsAuto.push(d.text);
              this.optionsAutoMap.set(d.text, d.value);
              if(this.hasMultiLevel) this.optionsAutoMapGroup.set(d.text+d.value, d.to_group);
              if(this.hasColor) this.optionsAutoMapColors.set(d.text+d.value, d.color);
            }
          });
          this.genericForm.patchValue({
            refresh: null
          })
          this.checkTmpValueMultiple();
        }
      } else if (this.structure[this.index]["type"] === "radio") {
      } else if (this.structure[this.index]["type"] === "toggle") {
      } else {
        if(this.value == '???'){
          this.value = -1;
          if(this.multiple) this.value = [this.value];
          this.genericForm.patchValue({
            [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value,
          });
        } else if(this.structure[this.index]["autoload_selection"] == 1 && data !== undefined && data.length && data.length == 1) {
          if(this.structure[this.index]["multiple"] != 1) this.structure[this.index]["tmp_value"] = data[0]['value'];
          else this.structure[this.index]["tmp_value"].push(data[0]['value'])
          this.value = this.structure[this.index]["tmp_value"];
          this.genericForm.patchValue({
            [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value,
          });
        }
      }
    }
  }

  // Filtrem els arrays per que no ens mostri la empty option
  filterFunction(arrayToFilter): any[] {
    if(arrayToFilter == undefined) return arrayToFilter;
    return arrayToFilter.filter(i => i.value !== "");
  }

  private normalize(text: string): string {
    if(text) {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "");
    } else return text;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    
    /*if ((value || "").trim() && this.optionsAutoMap.get(value)) {
      console.log(value, this.optionsAutoMap.get(value), this.optionsAutoMap, this.genericService.cloneVariable(this.structure[this.index]['tmp_value']), this.structure[this.index]['tmp_value'], "hioiouhiohi ---1");
      if(!this.structure[this.index]['tmp_value'] || !Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = []
      this.chipsList.push(value.trim());
      this.structure[this.index]['tmp_value'].push(this.optionsAutoMap.get(value))
    }*/

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.myControl.setValue(null);
  }

  remove(chip: string): void {
    const index = this.chipsList.indexOf(chip);
    if (index >= 0) {
      this.structure[this.index]['tmp_value'].splice(index, 1)
      this.chipsList.splice(index, 1);
      this.optionsAuto.unshift(chip)
      this.myControl.setValue('');

      let parent = this.structure[this.index]['id_functional_parent_initial'];
      let vnuevo = this.structure[this.index]['tmp_value'];
      let voriginal = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
      let vold = "";
      let vnew = "";
      let oldCount = voriginal !== null && voriginal.length ? voriginal.length- 1 : 0;
      let newCount = vnuevo.length - 1;

      this.optionsAutoMap.forEach((key, value) => {
        if(voriginal !== null) {   
          for(let vo of voriginal) {
            if(key == vo || value == vo){
              vold = vold + value;
              if (oldCount > 0) vold = vold + ", ";
              oldCount--;
            }
          }             
        }
        for(let t of vnuevo) {
          if(key == t || value == t) {
            vnew = vnew + value;
            if(newCount > 0) vnew = vnew + ", ";
            newCount--;
          }
        }
      });
            
      let type = 'autocomplete_';
      if(vnew === vold){
        if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
        if(this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']]) {
          // EJECUTAMOS FUNCIÓN
          let lastValueOnExecuteFunction = undefined;
          if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
          if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== vnew)) this.functionsControllerService.getFunctionFormField(this.structure[this.index]);
          setTimeout(() => {
            delete this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']];
          }, 500);
        }
      } else {
        let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
        if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
        this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, voriginal, this.structure[this.index]['label']);
      }
    }
  }
  
  changeRadioValue(event: MatRadioChange) {
    this.structure[this.index]["tmp_value"] = event.value;
    this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValue(
      event.value
    );
    this.structure[this.index]["invalid"] = false;
  }

  changeToggleValue(event: MatButtonToggleChange) {
    this.structure[this.index]["tmp_value"] = event.value;
    this.structure[this.index].form.controls[this.structure[this.index]['id_functional_area'] + '-' + this.bdField].setValue(
      event.value
    );
    this.structure[this.index]["invalid"] = false;
  }

  clear(field, from = 0){
    let find = false;
    // He de crear l'etiqueta
    // Opcio 1: tenia empty option, havia canviat a alguna cosa i torno a tenir empty option
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    if(this.genericService.formsChanged[parent] && this.genericService.paramControlVariables[parent]['indexParam'] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']]) {
      for(let elem in this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']]){
        // He d'eliminar l'etiqueta
        // Busco si en el formsChanged es el id que he borrat
        let type = this.structure[this.index]['type'] + "_";
        if(elem == type + this.structure[this.index]['id_functional_area']){
          find = true;
          // Miro si el original era null o era una altra cosa
          if(this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][elem]['old'] === '-')  {
            if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
            // EJECUTAMOS FUNCIÓN[this.genericService.paramControlVariables[parent]['indexParam']]
            let lastValueOnExecuteFunction = undefined;
            if(this.genericService.formsChanged && this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'] !== undefined) lastValueOnExecuteFunction = this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']]['lastValueOnExecuteFunction'];
            if(this.structure[this.index]["id_function"] > 0 && (lastValueOnExecuteFunction === undefined || lastValueOnExecuteFunction !== "-")) this.functionsControllerService.getFunctionFormField(this.structure[this.index]);
            setTimeout(() => {
              delete this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']];
            }, 500);
          } else this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], this.structure[this.index]['type'] + "_", this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][elem]['old'], '-', this.value, '-', this.structure[this.index]['label']);
        }
      }
    }
    // Opcio 2: tenia alguna cosa i ara no tinc empty option
    if(!find){
      // Creo l'etiqueta
      setTimeout(() => {       
        let vold, voriginal = this.genericService.originalInputsValues[this.structure[this.index]['id_functional_area'] + '-' + this.bdField]['value'];
        if(from == 1) vold = this.value;
        this.optionsAutoMap.forEach((key, value) => {
          if(key == voriginal || value == voriginal){
            vold = value;
          }
        });
        this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], this.structure[this.index]['type'] + "_", vold, '-', this.value, '-', this.structure[this.index]['label']);
      });
    }

    if(from == 0) {
      this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field] = ('');
      this.myControl.setValue('');
      this.myControl.touched;
      this.value = '';
      if(!this.multiple) this.structure[this.index]['tmp_value'] = '';
      this.structure[this.index]['form']['controls'][this.structure[this.index]['id_functional_area'] + '-' + field].setValue('');
      let element = <HTMLInputElement>document.getElementById(field);
      element.value = '';
    }
  }

  public notify(data) {
    const id_functional_area = data["id_functional_area"];
    if (
      id_functional_area != this.structure[this.index]["id_functional_area"] &&
      data["field"] == this.bdField
    ) {
      this.getDisplayedOptions();
    }
  }

  public saveFirstOption(event, auto, bdField){
    let firstOption = auto['options']['first'];
    if(firstOption !== undefined) {
      if(event.target.value != "" && !this.optionsAuto.includes(event.target.value)) this.genericForm.patchValue({ [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: firstOption['value'] });
    } else {
      this.clear(bdField);
    }
  }

  public onClick(str: any, event) {
    event.stopPropagation();
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if(this.structure[this.index]['id_function_old'] && this.structure[this.index]['id_function_old'] !== undefined) {
      this.structure[this.index]['id_function'] = this.functionsService.cloneVariable(this.structure[this.index]['id_function_old'])
    }
    this.structure[this.index]['is_function_selection_multiple'] = false;
    this.functionsControllerService.getFunction(str)
  }

  public onClickChipAutocomplete(event, val) {
    event.stopPropagation();
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    let newVal = -1;
    for(let o of this.arrayResultsInitial)  {
      if (o['text'] === val) {
        newVal = o['value'];
        break;
      }
    }
    this.structure[this.index]['tmp_value_multi_chip'] = newVal;
    this.structure[this.index]['param_intern'] = 1;
    this.structure[this.index]['id_function_old'] = this.functionsService.cloneVariable(this.structure[this.index]['id_function'])
    this.structure[this.index]['id_function'] = this.structure[this.index]['id_function_selection_multiple'];
    this.structure[this.index]['is_function_selection_multiple'] = true;
    this.structure[this.index]['autoExecuteFunction'] = false;
    this.functionsControllerService.getFunction(this.structure[this.index]);
  }
  
  public checkIfHasResults(array) {
    return array && array !== undefined && Array.isArray(array) && array.length > 0;
  }

  private assginServiceVar() {
    if(this.structure[this.index]["text_init"] && typeof this.structure[this.index]["text_init"] === 'string' &&this.structure[this.index]["text_init"].includes('{{') && !this.structure[this.index]['assigned_service_var']) {
      if(!this.structure[this.index]['tmp_value'].includes('{{') && (!this.structure[this.index]["tmp_value"] || this.structure[this.index]["tmp_value"] != -1 || this.structure[this.index]["tmp_value"] != "")) {
        this.typeofInitialValue();
        this.value = this.structure[this.index]["tmp_value"];
        this.structure[this.index]['assigned_service_var'] = true;
      } else {
        this.genericService.getServiceLanguage(this.structure[this.index]);
        setTimeout(() => {
          this.assginServiceVar()
        }, 500);
      }
    }
  }

  public typeofInitialValue() {
    let results = this.genericService.selectOptions[this.structure[this.index]['id_query']];
    if(results && results[0] && results[0]['value'] && typeof results[0]['value'] === 'string') {
      if(this.multiple) {
        if(!this.structure[this.index]['tmp_value']) this.structure[this.index]['tmp_value'] = [];
        if(!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = [this.structure[this.index]['tmp_value']];
        for(let i in this.structure[this.index]['tmp_value']) {
          this.structure[this.index]["tmp_value"][i] = this.structure[this.index]["tmp_value"][i].toString();
        }
      } else {
        if(this.structure[this.index]["tmp_value"]) this.structure[this.index]["tmp_value"] = this.structure[this.index]["tmp_value"].toString();
      }
    }
  }

  public onSelectionChange(event) {
    // Esto lo hacemos porque no entra en el suscribe cuando un selection multiple lo dejamos vacío (seleccionamos una opción y deseleccionamos de nuevo dejando vacío)
    if(this.multiple && event.value && event.value.length == 0) {
      this.updateDataValidity(this.structure[this.index]["tmp_value"]);
      this.functionsControllerService.getFunctionFormField(this.structure[this.index], true);  
    }
  }

  public addTmpValue(option) {
    // Esto lo hago porque en los casses que es multiple con multinivel no esta ejecutando el suscribe del formControl. Hay que arreglarlo y unificarlo. Además aun así no entra en vcsbcr = this.genericForm.valueChanges.subscribe((newToogleValue)
    if(this.multiple) {
      let val = this.optionsAutoMap.get(option);
      if(!this.structure[this.index]["tmp_value"]) {
        this.structure[this.index]["tmp_value"] = [];
      }
      if(!Array.isArray(this.structure[this.index]['tmp_value'])) {
        if(this.structure[this.index]['tmp_value']) {
          if(this.structure[this.index]['tmp_value'].includes(',')) {
            this.structure[this.index]['tmp_value'] = this.structure[this.index]['tmp_value'].split(",");
          } else {
            this.structure[this.index]['tmp_value'] = [this.structure[this.index]['tmp_value']]
          }
        } else {
          this.structure[this.index]['tmp_value'] = []
        }
      }
      if(!this.structure[this.index]["tmp_value"].includes(val)) {
        this.structure[this.index]["tmp_value"].push(val);
        this.addChip(option);
      }
    } else {
      this.structure[this.index]["tmp_value"] = option;
    }
    this.genericForm.patchValue({ [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.structure[this.index]["tmp_value"] });
  }

  checkTmpValueMultiple() {
    if(this.multiple && Array.isArray(this.structure[this.index]['tmp_value'])) {
      for(let t in this.structure[this.index]['tmp_value']){
        let val = Array.from(this.optionsAutoMap.entries()).filter(({ 1: v }) => v == this.structure[this.index]['tmp_value'][t]).map(([k]) => k)[0];
        if(val) this.addChip(val);
      }
    }
  }

  addChip(option) {
    if(!this.chipsList.includes(option)) this.chipsList.push(option);
    let index = this.optionsAuto.findIndex(opt => opt == option)
    if(index) this.optionsAuto.splice(index, 1);
    this.myControl.setValue('');
    let element = <HTMLInputElement>document.getElementById(this.structure[this.index]['bd_field']);
    if(element && element.value) {
      element.value = '';
      element.blur();
    }
    let auto = <HTMLInputElement>document.getElementById('autocomplete');
    if(auto) {
      auto.blur();
    }
  }
  
  removeChip(event: MouseEvent, chip: any): void {
    event.stopPropagation();
    this.remove(chip); // Implementa la lógica de eliminación aquí, si es necesario
  }

  chooseType() {
    let results = this.filterFunction(this.genericService.selectOptions[this.idQuery]);
    if(results && results.length && results.length > 15 && this.structure[this.index]['type'] == "selection") {
      this.structure[this.index]['type'] = "autocomplete";
    } else if(results && results.length && results.length < 10 && this.structure[this.index]['type'] == "autocomplete") {
      this.structure[this.index]['type'] = "selection";
    }
  }
  
}