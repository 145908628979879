<ng-container
    *ngIf="actualElement !== undefined && actualElement !== null && this.actualElement['formCreator'] !== undefined">
    <span class="generic-buttons-black" style="justify-content: center; display: flex;">
        <button mat-raised-button (click)="genericCreatorService.copyElement(this.actualElement)"
          matTooltip="Copy">Copiar Functional Area
          <i class="material-icons">content_copy</i>
        </button>
      </span>
    <mat-accordion class="options-expansion">
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 1"
            (opened)="genericCreatorService.expandedIndex = 1" (closed)="genericCreatorService.checkIndexClose(1)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Main Details
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">
                <div class="form-group" *ngIf="this.actualElement['id_functional_area']">
                    <mat-form-field appearance="outline">
                        <mat-label>Functional area</mat-label>
                        <input matInput placeholder="id_functional_area" formControlName="id_functional_area">
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Internal name (description)</mat-label>
                        <input matInput placeholder="Label" formControlName="internal_name" #internal_name
                            autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{internal_name.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div style="position: relative;" class="form-group"
                    *ngIf="!genericCreatorService.actualSelectedSubElement || genericCreatorService.actualSelectedSubElement['form_field'] !== 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Functional type</mat-label>
                        <mat-select formControlName="id_functional_type" required disabled>
                            <mat-option *ngFor="let item of genericCreatorService.arrayElementsTipo"
                                [value]="item.id_functional_type">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox *ngIf="conditionDependingOfFuntionalTypes('tabType')"
                        style="position: absolute; left: 0; bottom: -5px;" formControlName="tabType"
                        (change)="onTabType($event)">Tab full width</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="genericCreatorService.actualSelectedSubElement?.['form_field'] === 1">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="id_functional_type" required
                            (selectionChange)="onTypeChange($event)">
                            <mat-option *ngFor="let item of genericCreatorService.filteredFunctionalTypes"
                                [value]="item.id_functional_type">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('type')">
                    <mat-form-field appearance="outline">
                        <mat-label>Subtipo</mat-label>
                        <mat-select formControlName="type"
                            [required]="this.actualElement['id_functional_type'] === 113 || this.actualElement['id_functional_type'] === 5">
                            <mat-option *ngFor="let item of filteredFunctionalSubTypes"
                                [value]="item.internal_name">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                               
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="id_functional_status_initial" required>
                            <mat-option *ngFor="let item of genericCreatorService.filteredFunctionalStatus"
                                [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- start for hide -->
                <ng-container *ngIf="conditionDependingOfFuntionalTypes('hide')">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Hide</mat-label>
                            <mat-select formControlName="hide" required  (selectionChange)="changeHide($event)">
                                <mat-option *ngFor="let item of genericCreatorService.hideOptions"
                                    [value]="item.value">{{item.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['hide'].value == 2">
                        <mat-checkbox class="p-r-5" formControlName="hide_xs">Hide xs</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_s">Hide s</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_m">Hide m</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_l">Hide l</mat-checkbox>
                        <mat-checkbox class="p-r-5" formControlName="hide_xl">Hide xl</mat-checkbox>
                    </div>
                </ng-container>
                <!-- end for hide -->

                <div class="fix-class-slide-toggle" *ngIf="actualElement.id_functional_type == 105 || actualElement.id_functional_type == 141">
                    <mat-slide-toggle [(ngModel)]="genericCreatorService.listElementOptionToggle"
                        (change)="onToggle($event)" [ngModelOptions]="{standalone: true}">
                        <span style="font-weight: 500; white-space: break-spaces;">
                            ¿Quieres añadir subelementos? En caso contrario podrás añadir texto.
                        </span>
                    </mat-slide-toggle>
                </div>
                
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('param')">
                    <mat-checkbox formControlName="param">Param</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('param_intern')">
                    <mat-checkbox formControlName="param_intern">Param intern</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('sort')">
                    <mat-checkbox formControlName="sort">Sort</mat-checkbox>
                </div>

                <div class="form-group">
                    <mat-checkbox formControlName="dev">Element in Development</mat-checkbox>
                </div>
                <div class="form-group">
                    <mat-checkbox formControlName="dev_no">Element hided only in Development</mat-checkbox>
                </div>
                <div class="form-group">
                    <mat-checkbox class="p-r-5" formControlName="is_preloaded_element">Preloaded element</mat-checkbox>
                </div>
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 2"
        (opened)="genericCreatorService.expandedIndex = 2" (closed)="genericCreatorService.checkIndexClose(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Database
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_db') && loadTables()">
                    <mat-form-field appearance="fill" appearance="outline">
                        <mat-label>Database</mat-label>
                        <mat-select formControlName="id_db" (selectionChange)="loadTables()"
                            [required]="formChildrenIds.includes(actualElement.id_functional_type)">
                            <mat-option *ngFor="let item of genericCreatorService.db"
                                [value]="item.id_db">{{item.db_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('bd_table_autocomplete')">
                    <mat-form-field appearance="outline" *ngIf="this.actualElement['id_db'] && initializeTables()">
                    <mat-label>Table</mat-label>
                    <input type="text" placeholder="Bd Table" aria-label="bd_table_autocomplete" matInput
                            formControlName="bd_table_autocomplete" [matAutocomplete]="bd_tableAuto"
                            [required]="formChildrenIds.includes(actualElement.id_functional_type)">
                    <mat-autocomplete autoActiveFirstOption #bd_tableAuto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('bd_table', $event.option.value, 'internal_name'); loadFields()">
                        <mat-option *ngIf="this.genericCreatorService.filteredOptionsLengthBdTable > 100" [disabled]="true">
                        <span *ngIf="this.genericCreatorService.filteredOptionsLengthBdTable <= 200"
                                style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
                            {{this.genericCreatorService.filteredOptionsLengthBdTable}}
                        </span>
                        <span *ngIf="this.genericCreatorService.filteredOptionsLengthBdTable > 200"
                                style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
                            1-200 de {{this.genericCreatorService.filteredOptionsLengthBdTable}}
                        </span>
                        </mat-option>
                        <mat-option *ngFor="let item of this.genericCreatorService.tablesOptions | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('bd_field_autocomplete')">
                    <mat-form-field appearance="outline"
                                    *ngIf="this.actualElement['id_db'] && this.actualElement['bd_table'] && initializeFields()">
                    <mat-label>Field</mat-label>
                    <input type="text" placeholder="Bd Field" aria-label="bd_field_autocomplete" matInput
                            formControlName="bd_field_autocomplete" [matAutocomplete]="bd_fieldAuto"
                            [required]="formChildrenIds.includes(actualElement.id_functional_type)">
                    <mat-autocomplete autoActiveFirstOption #bd_fieldAuto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('bd_field', $event.option.value, 'internal_name')">
                        <mat-option *ngIf="this.genericCreatorService.filteredOptionsLengthBdField > 100" [disabled]="true">
                        <span *ngIf="this.genericCreatorService.filteredOptionsLengthBdField <= 200"
                                style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
                            {{this.genericCreatorService.filteredOptionsLengthBdField}}
                        </span>
                        <span *ngIf="this.genericCreatorService.filteredOptionsLengthBdField > 200"
                                style="display: flex; align-items: center; font-size: 12px; font-style: italic;">
                            1-200 de {{this.genericCreatorService.filteredOptionsLengthBdField}}
                        </span>
                        </mat-option>
                        <mat-option *ngFor="let item of this.genericCreatorService.fieldsOptions | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_query_autocomplete')">
                    <mat-form-field appearance="outline">
                    <mat-label>Query</mat-label>
                    <input type="text" placeholder="Id query" aria-label="internal_name" matInput
                            formControlName="id_query_autocomplete" [matAutocomplete]="autoIdQuery">
                    <mat-autocomplete autoActiveFirstOption #autoIdQuery="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_query', $event.option.value, 'id_query')">
                        <mat-option *ngFor="let item of genericCreatorService.idQueryOptions | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
                
                <div class="form-group">
                    <mat-form-field appearance="outline">
                    <mat-label>Query ngFor</mat-label>
                    <input type="text" placeholder="Id query ngFor" aria-label="internal_name" matInput
                            formControlName="id_query_ngfor_autocomplete" [matAutocomplete]="autoIdQueryNgFor">
                    <mat-autocomplete autoActiveFirstOption #autoIdQueryNgFor="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_query_ng_for', $event.option.value, 'id_query')">
                        <mat-option *ngFor="let item of genericCreatorService.idQueryOptionsNGFOR | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
                
                <div class="form-group">
                    <mat-form-field appearance="outline">
                    <mat-label>Relation ngFor</mat-label>
                    <input type="text" placeholder="Id relation ngFor" aria-label="internal_name" matInput
                            formControlName="id_table_relations_ngfor_autocomplete" [matAutocomplete]="autoIdRelationNgFor">
                    <mat-autocomplete autoActiveFirstOption #autoIdRelationNgFor="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_table_relations_ng_for', $event.option.value, 'id_relation')">
                        <mat-option *ngFor="let item of genericCreatorService.idRelationsOptionsNGFOR | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
             
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_table_relations')">
                    <mat-form-field appearance="outline">
                    <mat-label>Relation</mat-label>
                    <input type="text" placeholder="Id relation" aria-label="internal_name" matInput
                            formControlName="id_table_relations_autocomplete" [matAutocomplete]="autoIdRelation">
                    <mat-autocomplete autoActiveFirstOption #autoIdRelation="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_table_relations', $event.option.value, 'id_relation')">
                        <mat-option *ngFor="let item of genericCreatorService.idRelationsOptions | async" [value]="item">
                        {{item.internal_name}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                </div>
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="conditionDependingOfFuntionalTypesExpansion(['multiple', 'close_dialog_out', 'iframe_fullscreen', 'show_total_graphs', 'carousel_num_visible', 'carousel_num_scroll', 'show_buttons_carousel', 'carousel_circular', 'carousel_num_scroll_s', 'carousel_num_scroll_xs', 'carousel_num_visible_s', 'carousel_num_visible_xs', 'search', 'pagination', 'canExportTables', 'min_rows', 'max_rows', 'max_lengths', 'hide_booleans_false', 'expansion_start_opened', 'form_field_empty', 'autoload_selection'])"
        [expanded]="genericCreatorService.expandedIndex === 3" (opened)="genericCreatorService.expandedIndex = 3" (closed)="genericCreatorService.checkIndexClose(3)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Options
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('multiple')">
                    <mat-checkbox formControlName="multiple">Multiple</mat-checkbox>
                </div>
                
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('close_dialog_out')">
                    <mat-checkbox formControlName="close_dialog_out">Close dialog out</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('iframe_fullscreen')">
                    <mat-checkbox formControlName="iframe_fullscreen">Iframe fullscreen</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('show_total_graphs')">
                    <mat-checkbox formControlName="show_total_graphs">Show total graphs</mat-checkbox>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_visible')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible</mat-label>
                        <input matInput placeholder="Carousel num visible" formControlName="carousel_num_visible"
                            #carousel_num_visible autocomplete="off" maxlength="1500" required>
                        <mat-hint align="end">{{carousel_num_visible.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_scroll')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll</mat-label>
                        <input matInput placeholder="Carousel num scroll" formControlName="carousel_num_scroll"
                            #carousel_num_scroll autocomplete="off" maxlength="1500" required>
                        <mat-hint align="end">{{carousel_num_scroll.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('show_buttons_carousel')">
                    <mat-checkbox formControlName="show_buttons_carousel">Show buttons</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_circular')">
                    <mat-checkbox formControlName="carousel_circular">Carousel circular</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_scroll_s')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll s</mat-label>
                        <input matInput placeholder="carousel_num_scroll_s" formControlName="carousel_num_scroll_s"
                            #carousel_num_scroll_s autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_scroll_s.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_scroll_xs')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num scroll xs</mat-label>
                        <input matInput placeholder="carousel_num_scroll_xs" formControlName="carousel_num_scroll_xs"
                            #carousel_num_scroll_xs autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_scroll_xs.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_visible_s')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible s</mat-label>
                        <input matInput placeholder="carousel_num_visible_s" formControlName="carousel_num_visible_s"
                            #carousel_num_visible_s autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_visible_s.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('carousel_num_visible_xs')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Carousel num visible xs</mat-label>
                        <input matInput placeholder="carousel_num_visible_xs" formControlName="carousel_num_visible_xs"
                            #carousel_num_visible_xs autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{carousel_num_visible_xs.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('search')">
                    <mat-checkbox formControlName="search">Search</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('pagination')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Pagination</mat-label>
                        <input matInput placeholder="Pagination" formControlName="pagination" autocomplete="off">
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('canExportTables')">
                    <mat-checkbox formControlName="canExportTables">Can Export Tables</mat-checkbox>
                </div>
                
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('min_rows')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Min rows</mat-label>
                        <input matInput placeholder="min_rows" formControlName="min_rows" #min_rows autocomplete="off"
                            maxlength="1500">
                        <mat-hint align="end">{{min_rows.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('max_rows')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Max rows</mat-label>
                        <input matInput placeholder="max_rows" formControlName="max_rows" #max_rows autocomplete="off"
                            maxlength="1500">
                        <mat-hint align="end">{{max_rows.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('max_lengths')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Max lengths</mat-label>
                        <input required matInput placeholder="max_lengths" formControlName="max_lengths" #max_lengths
                            autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{max_lengths.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('max_lengths2')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Número máximo de ficheros</mat-label>
                        <input required matInput placeholder="max_files" formControlName="max_lengths" #max_lengths
                            autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{max_lengths.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('hide_booleans_false')">
                    <mat-checkbox formControlName="hide_booleans_false">Hide false values</mat-checkbox>
                </div>
        
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('expansion_start_opened')">
                    <mat-checkbox formControlName="expansion_start_opened">Expansion start opened</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('form_field_empty')">
                    <mat-checkbox formControlName="form_field_empty">¿Quieres que se pueda vaciar el campo?</mat-checkbox>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('autoload_selection')">
                    <mat-checkbox formControlName="autoload_selection">¿Quieres cargar automáticamente la primera opción en caso de que solo haya una?</mat-checkbox>
                </div>
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="conditionDependingOfFuntionalTypesExpansion(['id_product_mkt_autocomplete', 'id_product_mkt_no_autocomplete', 'id_condition_autocomplete', 'condition_internal_routing_autocomplete', 'condition_session_active', 'profiles_permissions'])"
        [expanded]="genericCreatorService.expandedIndex === 4" (opened)="genericCreatorService.expandedIndex = 4" (closed)="genericCreatorService.checkIndexClose(4)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Conditions & permissions
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_product_mkt_autocomplete')">
                    <mat-form-field appearance="outline">
                        <mat-label>Id product mkt</mat-label>
                        <input type="text" placeholder="Id product mkt" aria-label="internal_name" matInput
                                formControlName="id_product_mkt_autocomplete" [matAutocomplete]="id_product_mktAuto">
                        <mat-autocomplete autoActiveFirstOption #id_product_mktAuto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_product_mkt', $event.option.value, 'id_product')">
                        <mat-option *ngFor="let item of genericCreatorService.idProductMktOptions | async" [value]="item">
                            {{item.internal_name}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                    
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_product_mkt_no_autocomplete')">
                    <mat-form-field appearance="outline">
                        <mat-label>Id product mkt no</mat-label>
                        <input type="text" placeholder="Id product mkt no" aria-label="internal_name" matInput
                                formControlName="id_product_mkt_no_autocomplete" [matAutocomplete]="id_product_mkt_noAuto">
                        <mat-autocomplete autoActiveFirstOption #id_product_mkt_noAuto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_product_mkt_no', $event.option.value, 'id_product')">
                        <mat-option *ngFor="let item of genericCreatorService.idProductMktNoOptions | async" [value]="item">
                            {{item.internal_name}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                    
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('id_condition_autocomplete')">
                    <mat-form-field appearance="outline">
                        <mat-label>Id condition</mat-label>
                        <input type="text" placeholder="Id condition" aria-label="internal_name" matInput
                                formControlName="id_condition_autocomplete" [matAutocomplete]="id_conditionAuto">
                        <mat-autocomplete autoActiveFirstOption #id_conditionAuto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="fillAutocompleteOption('id_condition', $event.option.value, 'id_condition')">
                        <mat-option *ngFor="let item of genericCreatorService.idConditionOptions | async" [value]="item">
                            {{item.internal_name}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                    
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('condition_internal_routing_autocomplete')">
                    <mat-form-field appearance="outline">
                        <mat-label>Condition internal routing</mat-label>
                        <input type="text" placeholder="Condition internal routing" aria-label="internal_routing" matInput
                                formControlName="condition_internal_routing_autocomplete" [matAutocomplete]="condition_internal_routingAuto">
                        <mat-autocomplete autoActiveFirstOption #condition_internal_routingAuto="matAutocomplete" [displayWith]="displayRoutingFn"
                                        (optionSelected)="fillAutocompleteOption('condition_internal_routing', $event.option.value, 'internal_routing')">
                        <mat-option *ngFor="let item of genericCreatorService.conditionInternalRoutingOptions | async" [value]="item">
                            {{item.internal_routing}}
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>                  

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('condition_session_active')">
                    <mat-form-field appearance="outline">
                        <mat-label>Condition session active</mat-label>
                        <mat-select formControlName="condition_session_active">
                            <mat-option *ngFor="let item of genericCreatorService.condition_session_active"
                                [value]="item.id_session_active">{{item.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('profiles_permissions')">
                    <mat-form-field class="example-chip-list" appearance="outline">
                        <mat-label>Profiles permissions</mat-label>
                        <mat-chip-list #chipListProfilePermission>
                            <mat-chip
                                *ngFor="let item of actualElement['formCreator'].controls['permissions_group_functional_area'].value"
                                (removed)="removeProfilePermission(item, i)">
                                {{item}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                        <input type="text" matInput placeholder="Profiles permissions"
                            formControlName="profiles_permissions" #profilePermissionInput
                            [matAutocomplete]="autoProfilePermission" [matChipInputFor]="chipListProfilePermission"
                            [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes">
                        <mat-autocomplete autoActiveFirstOption #autoProfilePermission="matAutocomplete"
                            (optionSelected)="selectedProfilePermission($event, i)">
                            <mat-option *ngFor="let item of genericCreatorService.profilePermissionOptions | async"
                                [value]="item">{{item.internal_name}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="conditionDependingOfFuntionalTypesExpansion(['id_function_autocomplete'])"
        [expanded]="genericCreatorService.expandedIndex === 5" (opened)="genericCreatorService.expandedIndex = 5" (closed)="genericCreatorService.checkIndexClose(5)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Function
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">
                <!-- Start for id_function -->
                <ng-container *ngIf="conditionDependingOfFuntionalTypes('id_function_autocomplete')">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                          <mat-label>Id function</mat-label>
                          <input type="text" placeholder="Id function" aria-label="internal_name" matInput
                                 formControlName="id_function_autocomplete" [matAutocomplete]="autoIdFunction">
                          <mat-autocomplete autoActiveFirstOption #autoIdFunction="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="fillAutocompleteOption('id_function', $event.option.value, 'id_function')">
                            <mat-option *ngFor="let item of genericCreatorService.idFunctionOptions | async" [value]="item">
                              {{item.internal_name}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                    </div>                      
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Duplicate id functional area</mat-label>
                            <input matInput placeholder="Duplicate id functional area"
                                formControlName="duplicate_id_functional_area" #duplicate_id_functional_area
                                autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{duplicate_id_functional_area.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Internal routing</mat-label>
                            <input matInput placeholder="Internal routing" formControlName="internal_routing"
                                #internal_routing autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{internal_routing.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Internal routing id functional area</mat-label>
                            <input matInput placeholder="Internal routing id functional area"
                                formControlName="internal_routing_id_functional_area"
                                #internal_routing_id_functional_area autocomplete="off" maxlength="1500">
                            <mat-hint align="end">{{internal_routing_id_functional_area.value?.length ||
                                0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- End for id_function -->

            </form>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 6"
        (opened)="genericCreatorService.expandedIndex = 6" (closed)="genericCreatorService.checkIndexClose(6)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Styles
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('buttonType')">
                    <mat-form-field appearance="outline">
                        <mat-label>Button mode</mat-label>
                        <mat-select formControlName="buttonType" (selectionChange)="setValueForClass($event.value)">
                            <mat-option *ngFor="let item of buttonsTypesClasses" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <span fxLayout="row wrap" *ngIf="conditionDependingOfFuntionalTypes('text_align')">
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" style="margin: 1em auto" formControlName="text_align" (change)="onChangeTextAlign()">
                        <mat-button-toggle value="left" style="padding: 0.5em">
                          <mat-icon> format_align_left</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="center" style="padding: 0.5em">
                          <mat-icon> format_align_center</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="right" style="padding: 0.5em">
                          <mat-icon> format_align_right</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="justify" style="padding: 0.5em">
                          <mat-icon> format_align_justify</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </span>

                <span fxLayout="row wrap" *ngIf="conditionDependingOfFuntionalTypes('height')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Height</mat-label>
                        <input matInput placeholder="Height" formControlName="height" #height autocomplete="off"
                            maxlength="50">
                        <mat-hint align="end">{{height.value?.length || 0}}/50</mat-hint>
                    </mat-form-field>
                </span>

                <div class="aligns-section" *ngIf="actualElement['child'] && actualElement['child'].length && actualElement['child'].length > 0 && conditionDependingOfFuntionalTypes('align')">
                    <span fxLayout="row wrap" class="align_horizontal">
                        <label fxFlex="100">Horizontal align childs<span style="color: red;"> *</span></label>
                        <mat-button-toggle-group fxFlex="100" formControlName="align_horizontal" aria-label="Vertical Align" (change)="onChangeAlign()">
                            <mat-button-toggle value="start">Start</mat-button-toggle>
                            <mat-button-toggle value="center">Center</mat-button-toggle>
                            <mat-button-toggle value="end">End</mat-button-toggle>
                            <mat-button-toggle value="space-around" matTooltip="Space Around">Around</mat-button-toggle>
                            <mat-button-toggle value="space-between" matTooltip="Space Between">Between</mat-button-toggle>
                            <mat-button-toggle value="space-evenly" matTooltip="Space Evenly">Evenly</mat-button-toggle>    
                        </mat-button-toggle-group>
                        <i>Solo afecta si sobra espacio por los lados</i>
                    </span>
                    <span fxLayout="row wrap" class="align_vertical">
                        <label fxFlex="100">Vertical align childs<span style="color: red;"> *</span> </label>
                        <mat-button-toggle-group fxFlex="100" formControlName="align_vertical" aria-label="Horizontal Align" (change)="onChangeAlign()">
                            <mat-button-toggle value="start">Start</mat-button-toggle>
                            <mat-button-toggle value="center">Center</mat-button-toggle>
                            <mat-button-toggle value="end">End</mat-button-toggle>
                            <mat-button-toggle value="stretch">Stretch</mat-button-toggle>
                        </mat-button-toggle-group>
                        <i>Solo afecta si sobra espacio por abajo</i>
                    </span>
                    <div class="demo_align"><a href="https://tburleson-layouts-demos.firebaseapp.com/#/docs" target="_blank">Demo</a></div>
                </div>

                <div class="form-group"
                    [ngClass]="{'hidden': filteredCustomClasses && filteredCustomClasses.length === 0}"
                    *ngIf="conditionDependingOfFuntionalTypes('class_custom_original_creator')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Custom class</mat-label>
                        <mat-select (selectionChange)="onClassCustomOriginalSelect($event)"
                            formControlName="class_custom_original_creator" #class_custom_original_creator multiple>
                            <mat-option *ngFor="let custom_class of filteredCustomClasses"
                                [value]="custom_class.internal_name">{{custom_class.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('class')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Angular class</mat-label>
                        <mat-select formControlName="class" #class
                            [required]="this.genericCreatorService.id_functional_type === 5 || this.genericCreatorService.id_functional_type === 6 || this.genericCreatorService.id_functional_type === 12">
                            <mat-option *ngFor="let class of filteredAngularClasses"
                                [value]="class.internal_name">{{class.internal_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('color_card')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Color</mat-label>
                        <input matInput placeholder="#000000" type="text" formControlName="color_card" #color_card
                            autocomplete="off" required>
                        <mat-hint align="end">Asegúrate que contenga “#"</mat-hint>
                    </mat-form-field>
                </div>

                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('style')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Style</mat-label>
                        <input matInput placeholder="CSS" formControlName="style" #style autocomplete="off"
                            maxlength="1500">
                        <mat-hint align="end">{{style.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>

                <!-- start for width -->
                <ng-container *ngIf="conditionDependingOfFuntionalTypes('width')">
                    <div class="form-group" style="position: relative;">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Width</mat-label>
                            <input matInput type="number" placeholder="width" formControlName="width_general"
                                autocomplete="off" min="0" max="100">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-checkbox style="position: absolute; left: 0; bottom: -5px;"
                            formControlName="isAutomaticResponsive">Automatic responsive</mat-checkbox>
                    </div>
                    <ng-container
                        *ngIf="this.actualElement['formCreator'].controls['isAutomaticResponsive'].value == true">
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width xs</mat-label>
                                <input matInput type="number" placeholder="Width xs" formControlName="width_xs"
                                    autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width s</mat-label>
                                <input matInput type="number" placeholder="Width s" formControlName="width_s"
                                    autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width m</mat-label>
                                <input matInput type="number" placeholder="Width m" formControlName="width_m"
                                    autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width l</mat-label>
                                <input matInput type="number" placeholder="Width l" formControlName="width_l"
                                    autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Width xl</mat-label>
                                <input matInput type="number" placeholder="Width xl" formControlName="width_xl"
                                    autocomplete="off" min="0" max="100">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- end for width -->

                <!-- Start for icon -->
                <ng-container *ngIf="conditionDependingOfFuntionalTypes('icon')">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>Icon</mat-label>
                            <input matInput #iconInput formControlName="icon" maxlength="1500" placeholder="Icon">
                            <mat-hint align="start"><strong><a href="https://fonts.google.com/icons"
                                        target="_blank">https://fonts.google.com/icons</a></strong> </mat-hint>
                            <mat-hint align="end">{{iconInput.value.length}} / 1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['icon'].value != null">
                        <mat-form-field appearance="outline">
                            <mat-label>Icon type</mat-label>
                            <mat-select formControlName="icon_type_original" (selectionChange)="changeIconType($event)">
                                <mat-option *ngFor="let item of genericCreatorService.class_angular_icon"
                                    [value]="item.internal_name">{{item.internal_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <!-- End for icon -->
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="conditionDependingOfFuntionalTypesExpansion(['label', 'text', 'description', 'tooltip', 'url', 'badge'])"
        [expanded]="genericCreatorService.expandedIndex === 7" (opened)="genericCreatorService.expandedIndex = 7" (closed)="genericCreatorService.checkIndexClose(7)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Languages
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="this.actualElement['formCreator']">
               
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('label')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Label</mat-label>
                        <textarea matInput formControlName="label" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('text')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Text</mat-label>
                        <textarea matInput formControlName="text" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('description')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('tooltip')">
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Tooltip</mat-label>
                        <input matInput placeholder="Tooltip" formControlName="tooltip" #tooltip autocomplete="off" maxlength="1500">
                        <mat-hint align="end">{{tooltip.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field> -->
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Tooltip</mat-label>
                        <textarea matInput formControlName="tooltip" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="1"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-group" *ngIf="conditionDependingOfFuntionalTypes('url')">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Url</mat-label>
                        <input matInput placeholder="Url" formControlName="url" #url autocomplete="off"
                            maxlength="1500">
                        <mat-hint align="end">{{url.value?.length || 0}}/1500</mat-hint>
                    </mat-form-field>
                </div>
                
               <!-- Start for Badge -->
                <ng-container *ngIf="conditionDependingOfFuntionalTypes('badge')">
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Badge</mat-label>
                            <input matInput placeholder="Badge" formControlName="badge" #badge autocomplete="off"
                                maxlength="1500">
                            <mat-hint align="end">{{badge.value?.length || 0}}/1500</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="this.actualElement['formCreator'].controls['icon'].value != null">
                        <mat-form-field appearance="outline">
                        <mat-label>Id condition badge</mat-label>
                        <input type="text" placeholder="Id condition badge" aria-label="id_condition_badge_autocomplete" matInput
                                formControlName="id_condition_badge_autocomplete" [matAutocomplete]="autoConditionBadge">
                        <mat-autocomplete autoActiveFirstOption #autoConditionBadge="matAutocomplete" [displayWith]="displayFn"
                                            (optionSelected)="fillAutocompleteOption('id_condition_badge', $event.option.value, 'id_condition')">
                            <mat-option *ngFor="let item of genericCreatorService.idConditionBadgeOptions | async" [value]="item">
                            {{item.internal_name}}
                            </mat-option>
                        </mat-autocomplete>
                        </mat-form-field>
                    </div>                      
                </ng-container>
                <!-- End for Badge -->
            </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="actualElement.hasSubEle" [expanded]="genericCreatorService.expandedIndex === 8"
            (opened)="genericCreatorService.expandedIndex = 8" (closed)="genericCreatorService.checkIndexClose(8)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Sub Elements
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap" fxFlex="100" cdkDropList [cdkDropListData]="actualElement.child"
                (cdkDropListDropped)="drop($event)">
                <!-- <div class="fix-class-slide-toggle" *ngIf="actualElement.id_functional_type == 99">
                    <mat-slide-toggle [(ngModel)]="genericCreatorService.listElementOptionToggle"><span style="font-weight: 500; white-space: break-spaces;">¿Quieres añadir subelementos en cada elemento de la lista? En caso contrario podrás añadir texto para cada elemento de lista.</span></mat-slide-toggle>
                </div> -->
                <div *ngFor="let child of actualElement.child | keyvalue : genericService.returnZero" cdkDrag
                    fxFlex="100">

                    <ng-container
                        *ngIf="actualElement['child'][child.key] && conditionsClientService.evaluateIfElements(actualElement, child.key) && actualElement['child'][child.key]['id_functional_status_general'] !== 2">
                        <ng-container *ngIf="!genericCreatorService.listElementOptionToggle">
                            <div fxLayout="row" class="options-subelements" [cdkDragData]="element" fxFlex="100">
                                <span class="option-buttons">
                                    <span class="generic-buttons-black">
                                        <button mat-raised-button
                                            (click)="openSettingPopup(actualElement['child'][child.key])">
                                            <i class="material-icons">edit</i>
                                        </button>
                                    </span>
                                    <span class="generic-buttons-black">
                                        <button mat-raised-button>
                                            <i class="material-icons">open_with</i>
                                        </button>
                                    </span>
                                    <span class="generic-buttons-red">
                                        <button mat-raised-button
                                            (click)="genericCreatorService.deleteElement(actualElement['child'][child.key])">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </span>
                                    <span class="generic-buttons-black generic-creator-add">
                                        <button mat-raised-button
                                            (click)="genericCreatorService.copyElement(actualElement['child'][child.key])"
                                            matTooltip="Copy">
                                            <i class="material-icons">content_copy</i>
                                        </button>
                                    </span>
                                </span>
                                <div style="margin-left: 1em;"
                                    *ngIf="actualElement['child'][child.key].label; else elseBlock">
                                    {{actualElement['child'][child.key].label}}</div>
                                <ng-template #elseBlock>
                                    <div style="margin-left: 1em;">{{actualElement['child'][child.key].internal_name}}
                                    </div>
                                </ng-template>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="genericCreatorService.listElementOptionToggle">
                            <span fxLayout="row" class="options-subelements" [cdkDragData]="element" cdkDrag
                                fxFlex="100">Element {{keyvalue}}</span>
                        </ng-container>
                    </ng-container>
                </div>
                <div fxLayout="row wrap" fxFlex="100" style="text-align: center; margin-bottom: 1.5em"
                    *ngIf="!genericCreatorService.isAddingElement && actualElement['id_functional_type'] !== 100">
                    <span class="generic-buttons-blue generic-creator-add">
                        <button mat-raised-button (click)="addNewItem()">
                            <i class="material-icons">add</i>
                        </button>
                        <button mat-raised-button (click)="genericCreatorService.pasteElement(actualElement['idTmp'])"
                            matTooltip="Paste" *ngIf="genericCreatorService.copiedElement && !genericCreatorService.isDSB(genericCreatorService.copiedElement)">
                            <i class="material-icons">content_paste</i>
                        </button>
                    </span>
                </div>
                <div fxLayout="row wrap" fxFlex="100" style="text-align: center; margin-bottom: 1.5em"
                    *ngIf="!genericCreatorService.isAddingElement && actualElement['id_functional_type'] == 100">
                    <span class="generic-buttons-blue generic-creator-add">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
                            <i class="material-icons">add</i>
                        </button>
                    </span>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="addNewCardItem('header')" *ngIf="!isHeaderAdded">
                            <span>Header</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('content')" *ngIf="!isContentAdded">
                            <span>Content</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('action')" *ngIf="!isActionAdded">
                            <span>Actions</span>
                        </button>
                        <button mat-menu-item (click)="addNewCardItem('dynamicStructure')" *ngIf="!isDynamicAdded">
                            <span>Dynamic structure</span>
                        </button>
                    </mat-menu>
                </div>
                <div fxLayout="row wrap" fxFlex="100" *ngIf="genericCreatorService.isAddingElement">
                    <span style="color: rgb(var(--tertiary-color));">
                        You can not add sub element now, first create an element and later you can add it.
                    </span>
                </div>
            </div>
        </mat-expansion-panel>
        <!-- Start Data validity Expansion -->
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 9"
            (opened)="genericCreatorService.expandedIndex = 9" (closed)="genericCreatorService.checkIndexClose(9)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Data validity
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-top: 0.5em" fxLayout="row wrap">
                <mat-accordion>
                    <ng-container
                        *ngFor="let item of actualElement['excluding_data_type_fa_restrictions'] | keyvalue : genericService.returnZero; let i = index;">
                        <mat-expansion-panel *ngIf="actualElement['excluding_data_type_fa_restrictions'][i]['mode_type'] !== 'delete'">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Data validity {{i+1}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <span class="generic-buttons-red">
                                        <button mat-raised-button (click)="removeExcludingFaRestrictionObj(i)"
                                            matTooltip="Delete">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <form
                                [formGroup]="actualElement['excluding_data_type_fa_restrictions'][item.key]['excludingFaRestrictionForm']"
                                fxLayout="row wrap" fxFlex="100">
                                <mat-form-field appearance="outline">
                                    <mat-label>Data type</mat-label>
                                    <!--<input type="text" placeholder="Data type" aria-label="internal_name" matInput
                                        formControlName="id_data_type" [matAutocomplete]="auto">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                        [displayWith]="displayFn">
                                        <mat-option *ngFor="let item of genericCreatorService.filteredOptions | async"
                                            [value]="item">{{item.internal_name}}</mat-option>
                                    </mat-autocomplete>-->
                                    <input matInput placeholder="Data type" formControlName="id_data_type"
                                        #id_data_type autocomplete="off">
                                </mat-form-field>
                                            

                                <mat-form-field appearance="outline">
                                    <mat-label>Admin area</mat-label>
                                    <mat-select formControlName="id_admin_area">
                                        <mat-option *ngFor="let item of genericCreatorService.admin_area"
                                            [value]="item.id_admin_area">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                               <!--<mat-form-field class="example-chip-list" appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-chip-list #chipList>
                                        <mat-chip
                                            *ngFor="let item of actualElement['excluding_data_type_fa_restrictions'][item.key]['excludingFaRestrictionForm'].controls['id_company_type'].value"
                                            (removed)="removeCompany(item, i)">
                                            {{item}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <input type="text" matInput placeholder="Company type"
                                        formControlName="companyTypes" #companyTypeInput
                                        [matAutocomplete]="autoCompanyType" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes">
                                    <mat-autocomplete autoActiveFirstOption #autoCompanyType="matAutocomplete"
                                        (optionSelected)="selectedCompany($event, i)">
                                        <mat-option
                                            *ngFor="let item of genericCreatorService.companyTypeOptions | async"
                                            [value]="item.companyTypes">
                                            {{item.internal_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>-->

                                <mat-form-field appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-select formControlName="id_company_type">
                                        <mat-option *ngFor="let item of genericCreatorService.company_type"
                                            [value]="item.id_company_type">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Functional status general</mat-label>
                                    <mat-select formControlName="id_functional_status_general">
                                        <mat-option *ngFor="let item of genericCreatorService.functionalStatus"
                                            [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
                <span class="generic-buttons-blue generic-creator-add" fxFlex="100"
                    style="text-align: center; margin-top: 1em">
                    <button mat-raised-button (click)="addNewExcludingFaRestrictionObj()">
                        <i class="material-icons">add</i>
                    </button>
                </span>
            </div>
        </mat-expansion-panel>
        <!-- End Data validity Expansion -->

        <!-- Start Restrictions Expansion -->
        <mat-expansion-panel [expanded]="genericCreatorService.expandedIndex === 10" *ngIf="!this.genericCreatorService.isStatic"
            (opened)="genericCreatorService.expandedIndex = 10" (closed)="genericCreatorService.checkIndexClose(10)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Restrictions
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="margin-top: 0.5em" fxLayout="row wrap">
                <mat-accordion>
                    <ng-container
                        *ngFor="let item of actualElement['functional_area_restrictions'] | keyvalue : genericService.returnZero let i = index;">
                        <mat-expansion-panel *ngIf="actualElement['functional_area_restrictions'][i]['mode_type'] !== 'delete'">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="material-icons red" style="margin-right: 5px;"
                                        (click)="removeRestrictionObj(i)" matTooltip="Delete">delete</i>
                                    R-{{i+1}}:
                                    {{actualElement['functional_area_restrictions'][item.key]['restrictionForm'].controls['id_company_type'].value}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <form
                                [formGroup]="actualElement['functional_area_restrictions'][item.key]['restrictionForm']"
                                fxLayout="row wrap" fxFlex="100">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Activation date</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="activation_date"
                                        (click)="picker.open()">
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Admin area</mat-label>
                                    <mat-select formControlName="id_admin_area">
                                        <mat-option *ngFor="let item of genericCreatorService.admin_area"
                                            [value]="item.id_admin_area">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-select formControlName="id_company_type">
                                        <mat-option *ngFor="let item of genericCreatorService.company_type"
                                            [value]="item.id_company_type">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!--<mat-form-field class="example-chip-list" appearance="outline">
                                    <mat-label>Company type</mat-label>
                                    <mat-chip-list #chipList>
                                        <mat-chip *ngFor="let item of actualElement['functional_area_restrictions'][item.key]['restrictionForm'].controls['id_company_type'].value"
                                            (removed)="removeCompanyRestriction(item, i)">
                                            {{item}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <input type="text" matInput
                                        placeholder="Company type"
                                        formControlName="companyTypes"
                                        #companyTypeInputRestriction
                                        [matAutocomplete]="autoCompanyTypeRestriction"
                                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="genericCreatorService.separatorKeysCodes">
                                    <mat-autocomplete autoActiveFirstOption #autoCompanyTypeRestriction="matAutocomplete" (optionSelected)="selectedCompanyRestriction($event, i)">
                                        <mat-option *ngFor="let item of genericCreatorService.companyTypeOptionsRestriction | async" [value]="item">
                                          {{item.internal_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>-->
                                <mat-form-field appearance="outline">
                                    <mat-label>Functional status general</mat-label>
                                    <mat-select formControlName="id_functional_status_general">
                                        <mat-option *ngFor="let item of genericCreatorService.functionalStatus"
                                            [value]="item.id_functional_status">{{item.internal_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
                <span class="generic-buttons-blue generic-creator-add" fxFlex="100"
                    style="text-align: center; margin-top: 1em">
                    <button mat-raised-button (click)="addNewRestriction()">
                        <i class="material-icons">add</i>
                    </button>
                </span>
            </div>
        </mat-expansion-panel>
        <!-- End Restrictions Expansion -->
    </mat-accordion>
</ng-container>