<span *ngIf="loaded" id="table-filters-generic" fxFlex="100" fxLayout="row wrap" style="padding-bottom: 3em; overflow: scroll;">
    <span fxFlex="100" style="padding: 1em 1em 0em 1em; background: rgba(var(--primary-color), 0.25);">
        <mat-form-field fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" appearance="outline">
            <mat-label>Nombre agrupación</mat-label>
            <input matInput [(ngModel)]="this.nameGroup">
        </mat-form-field>
    </span>
    <div fxLayout="column" fxFlex="100" style="padding: 20px 1em;">
        <ng-template #groupTemplate let-group let-groupParent="groupParent">
        <div style="margin-left: 20px;">
            <mat-form-field appearance="outline">
                <mat-label>Seleccionar columna de agrupación</mat-label>
                <mat-select [(ngModel)]="group.selectedColumn" [(disabled)]="group.isDisabled">
                    <mat-option *ngFor="let c of availableColumns(group)" [value]="c.column">
                    {{ c.label }}
                    </mat-option>
                </mat-select>
                <mat-icon matPrefix *ngIf="group.isDisabled" (click)="deleteGroup(groupParent); $event.stopPropagation()" style="color: red !important;">delete</mat-icon>
                <mat-icon matSuffix *ngIf="group.isDisabled" (click)="editGroup(group)">edit</mat-icon>
            </mat-form-field>
            <div *ngIf="group.selectedColumn">
            <button mat-icon-button *ngIf="!group.isDisabled && group.level < 4" (click)="addSubGroup(group)">
                <mat-icon>add</mat-icon> Añadir un subgrupo
            </button>
            <span *ngIf="!group.isDisabled && group.level == 4" style="font-style: italic; color: #6d6d6d;">Máx. 4 niveles</span>
            </div>
            <ng-container *ngFor="let subGroup of group.subGroups">
                <ng-container *ngTemplateOutlet="groupTemplate; context: { $implicit: subGroup, groupParent: group }"></ng-container>
            </ng-container>
        </div>
        </ng-template>
        <!-- Iniciar el renderizado recursivo con rootGroup -->
        <ng-container *ngTemplateOutlet="groupTemplate; context: { $implicit: rootGroup, groupParent: null }"></ng-container>
    </div>
</span>
<span class="buttons-table-filter">
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="closeBottomSheet()">
        <button mat-button>Cerrar sin modificar agrupación</button>
    </span>
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="resetFilterTableValues()">
        <button mat-button>Resetear agrupación</button>
    </span>
    <span class="generic-buttons-blue-revert" (click)="filterTableValues()">
        <button mat-button>Aplicar agrupación</button>
    </span>
</span>