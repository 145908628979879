import { Component, OnInit, Input } from '@angular/core';
import { FunctionsService } from 'app/services/generic/functions.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-carousel-generic',
  templateUrl: './carousel-generic.component.html',
  styleUrls: ['./carousel-generic.component.css']
})
export class CarouselGenericComponent implements OnInit {
  public responsiveOptions;
  public items = [];
  public carousel_circular;

  @Input() structure: any;
  @Input() index: number;
  @Input() param : any = null;

  public idTable = null;
  public idRow = null;
  public idTableIndex = null;
  public functionFinish = {};

  // Carousel Values
  public minElement = 0;
  public maxElement = -1;
  public loaded = false;
  public breakpoints = [1024, 768, 560];
  public page = 0;
  public heightIMG = 1;

  constructor(private genericService: GenericService, public functionsService: FunctionsService, private functionsControllerService: FunctionsControllerService) { 
    this.setResponsiveOptions(3, 3, 2, 2, 1, 1);
  }

  ngOnInit(): void {
    console.log(this.structure[this.index]['items'], "iteeeems");
    this.setResponsiveOptions(this.structure[this.index]['carousel_num_visible'], this.structure[this.index]['carousel_num_scroll'], this.structure[this.index]['carousel_num_visible_s'], this.structure[this.index]['carousel_num_scroll_s'], this.structure[this.index]['carousel_num_visible_xs'], this.structure[this.index]['carousel_num_scroll_xs']);
    this.setPage(this.page, this.structure[this.index]['carousel_num_scroll'], this.structure[this.index]['carousel_num_visible'],
      this.structure[this.index]['carousel_num_scroll_s'], this.structure[this.index]['carousel_num_visible_s'], 
      this.structure[this.index]['carousel_num_scroll_xs'], this.structure[this.index]['carousel_num_visible_xs']);
    this.functionFinish[this.index] = true;

    if(this.param !== null) {
      let respPar = this.genericService.fillTableDataFromParam(this.param, this.idTable, this.idRow, this.idTableIndex);
      this.idTable = respPar['idTable'];
      this.idRow = respPar['idRow'];
      this.idTableIndex = respPar['idTableIndex'];
    }
  }

  public setPage(page, numScroll, numVisible, numScroll_s, numVisible_s, numScroll_xs, numVisible_xs) {
    setTimeout(() => {
      if(page.page !== undefined) this.page = page.page;
      console.log("page page:", page, numScroll, numVisible, numScroll_s, numVisible_s, numScroll_xs, numVisible_xs, this.page);
      let windowActual = window.innerWidth;
      if(windowActual > this.breakpoints[0]) {
        this.minElement = this.page * numScroll - 1;
        this.maxElement = this.minElement + numVisible + 1;
        this.heightIMG = numVisible;
      } else if(windowActual > 1250) {
        this.minElement = this.page * numScroll_s - 1;
        this.maxElement = this.minElement + numVisible_s + 1;
        this.heightIMG = numVisible_s;
      } else {
        this.minElement = this.page * numScroll_xs - 1;
        this.maxElement = this.minElement + numVisible_xs + 1;
        this.heightIMG = numVisible_xs;
      }
      if(!this.loaded) this.loaded = true;
    });
  }

  public setResponsiveOptions(numVisible, numScroll, numVisible_s, numScroll_s, numVisible_xs, numScroll_xs) {
    this.responsiveOptions = [
      {
        breakpoint: this.breakpoints[0] + "px",
        numVisible: numVisible,
        numScroll: numScroll
      },
      {
        breakpoint: this.breakpoints[1] + "px",
        numVisible: numVisible_s,
        numScroll: numScroll_s
      },
      {
        breakpoint: this.breakpoints[2] + "px",
        numVisible: numVisible_xs,
        numScroll: numScroll_xs
      }
    ];
  }

  onClickEvent(product, event) {
    console.log('entro product', product);
    if (product && product['structure'] && product['structure_parent']) {
      this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      product['structure']['ngForId'] = product['structure_parent']['ngForId']; // Pasamos la del parent porque sino no coge bien los paramInters (por temas del NgFor) -> TODO: arreglar getParamInterns para que funcione bien en NGFor usando NgForIndex, sino no puedes coger información de un indice superior (si tienes varios ngFors)
      //this.functionsControllerService.getFunction(eventTrigger['structure']);
      if(this.idTable == null && this.idRow == null) {
        let data = {done: false};
        this.functionFinish[this.index] = false;
        console.log('this.structure[this.index]', product, 'data', data);
        this.functionsControllerService.getFunction(product['structure'], this.param, data);
        this.functionFinish[this.index] = data.done;
      } else {
        this.genericService.tablesActualRows.push({ "id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex });
        this.functionsControllerService.getFunction(product['structure'], this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
      }
    }
  }
}
