import {Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {SelectionModel} from '@angular/cdk/collections';

//SERVICES
import { FunctionsService } from '../../../../services/generic/functions.service';
import {RoutingService} from "../../../../services/routing/routing.service";
import {GenericService} from "../../../../services/generic/generic.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "../../../../services/auth/auth.service";
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  // @Input() functionId: number;
  @Input() structure = null;
  @Input() index: number;
  @Input() indexTable: number;
  @Input() group: any;
  @Input() oldBdField: any;
  @Input() oldValue: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public idQuery: number;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns = [];
  public columnsToExport = [];
  public sticky;
  public paginatorOptions;
  public aux = [];
  public actualRow = null;
  public expandedCol = null;
  public expandedElement;
  public pageIndex : number = 0;
  public pageSize = 0;
  public loaded = false;
  public loadedAfterView = false;
  public clickedRow = null;
  public lastSort: Sort = { active: '', direction: '' };
  public getHeightV;
  public getHeightTable2V;
  public getHeightTableV;
  public caluclated = false;
  public expandedElementLast = null;
  public selection = new SelectionModel<any>(true, []);

  public actual_id_functional_area;
  public idFa;

  constructor(public functionsService: FunctionsService,
              public functionsControllerService: FunctionsControllerService,
              public routingService: RoutingService,
              public genericService: GenericService,
              public conditionsClientService: ConditionsClientService,
              public authService: AuthService,
              public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.actual_id_functional_area = this.genericService.getActualInitialFA(this.structure[this.index]);
    this.idFa = this.genericService.cloneVariable(this.structure[this.index]['id_functional_area']).toString();
    this.sticky = this.structure[this.index]['sticky'] == 1;
    this.paginatorOptions = this.structure[this.index]['paginator'] === null ? null : this.structure[this.index]['paginator'] != 0 ? this.structure[this.index]['paginator'].split(',') : null
    this.idQuery = this.structure[this.index]['id_query'];
    this.displayedColumns = this.structure[this.index]['displayedColumns'];
    this.aux = this.structure[this.index]['auxTable'];
    for (let e in this.structure[this.index]['child']) {
      if (this.structure[this.index]['child'][e]['type'] == 'expansion') this.expandedCol = this.structure[this.index]['child'][e];
      this.genericService.initElementInFrontend(this.structure[this.index]['child'][e], e, this.structure[this.index]);
    }
    this.dataSource = this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']]['dataSourceTables'][this.indexTable];
    this.genericService.initTableFilter(this.dataSource);
    this.loaded = true;
  }
  
  ngAfterViewInit() {
    if(!(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
      let param = this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['groups'][this.indexTable];
      console.log('parammmmmm', param, this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.structure[this.index]['id_functional_area']]['groups']);
      if(param && param !== undefined && param !== null) {

        if(param['paginator']) { // Aplicar el último Paginator
          this.paginator = param['paginator'];
        }

        if(param['pageSize'] && param['pageSize'] > 0) {
          this.pageSize = this.genericService.cloneVariable(param['pageSize']);
        }
        
        if(param['pageIndex'] && param['pageIndex'] < this.getNumPages()) { // Ir a la página que estábamos
          this.pageIndex = this.genericService.cloneVariable(param['pageIndex']);
        }
        
        if(param['lastSort']) { // Aplicar el ordenar
          this.lastSort = param['lastSort'];
        }
        
        this.applySort(); // Hay que hacerlo siempre pues si no hay lastSort pero si pageIndex, el pageIndex falla
      } else {
        param = this.group;
      }
    }

    this.structure[this.index]['expandedElement'] = this.dataSource.filteredData.find(row => row[this.oldBdField] === this.oldValue)
    if(this.expandedCol !== null && this.oldValue !== null && this.oldValue !== undefined && this.oldValue != -1 && this.oldBdField !== null && this.oldBdField !== undefined && this.oldBdField != '') {
      let from = 0;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.headerNoLoguedId) from = 1;
      if(this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerId || this.structure[this.index]['id_functional_parent_initial'] == this.genericService.footerNoLoguedId) from = 2;
      this.genericService.loadExpansion(this.dataSource.filteredData.find(row => row[this.oldBdField] === this.oldValue), from)
    }   
    this.loadedAfterView = true;
    if(this.sort) this.dataSource.sort = this.sort;
    if(this.paginatorOptions !== null) this.dataSource.paginator = this.paginator;

    // Joan: Codi per ordenar nombres i dates - Caldrà revisar quan es localitzi per altres països
    this.dataSource.sortingDataAccessor = (item, property) => {
      let filter = this.genericService.cloneVariable(item[property]);
      let idFaSplited = property.split('-');
      let idFa = null;
      if(idFaSplited[0]) idFa = this.genericService.findElementWithId(idFaSplited[0], false, false, true);
      if(idFa && filter && typeof filter == "string") {
        if(idFa['hint']) filter = filter.replace(idFa['hint'], "");
        if(idFa['text']) filter = filter.replace(idFa['text'], "");
      }
      if (typeof filter == "string" && this.isValidDate(filter.substring(0, 10))) {
        return filter.substring(6, 10) + filter.substring(3, 5) + filter.substring(0, 2) + filter.substr(10);
      } else if (typeof filter == "string" && !isNaN(parseFloat(filter.replace(/\./g, "").replace(",", ".")))) { // ...and ensure strings of whitespace fail
        return parseFloat(filter.replace(/\./g, "").replace(",", "."));
      } else {
        return filter;
      }
    }

    this.dataSource.data = this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].groupedDataInfo[this.indexTable];
    setTimeout(() => {
      this.calculateHeights();
    });
    this.structure[this.index]['wasInitialized'] = true;
    this.initColumnsToExport();
  }

  ngOnDestroy() {
    this.saveFilter();
  }
  
  getRowId(rowID: any, originalRowID: any) {
    if(originalRowID !== undefined) rowID = originalRowID;
    return rowID;
  }

  massiveAction(event) {
    let selected = this.selection.selected;
    let selectedRows = [];
    let isFirst = true;
    for(let i in selected) {
      selectedRows.push(selected[i]);
      // if(!this.isAllSelected()) { TODO JAN
        this.pushActualRow(selected[i]['rowID'], isFirst);
        if(isFirst) isFirst = false;
      // }
    }
    let ele = this.genericService.cloneVariable(this.structure[this.index]);
    ele['massive_action'] = true; // Lo ponemos porque sino los params interns no actuan como si fuese un boton sino como una table y solo coje esos params y no los de toda la pantalla
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    this.functionsControllerService.getFunction(ele, selectedRows);
  }

  rowClick(event : any, row : any, rowID: any) : void {
    if(this.structure[this.index]['type'] != "checkbox") {
      this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
        this.oldValue = row[this.oldBdField]
      }
      this.pushActualRow(rowID);
      this.functionsControllerService.getFunction(this.structure[this.index], this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].groupedDataInfo[this.indexTable][rowID]);
    }
  }

  rowClickImage(event : any, row : any, rowID: any, id: any) : void {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if(this.oldBdField !== undefined && this.oldBdField !== null && this.oldBdField !== '') {
      this.oldValue = row[this.oldBdField]
    }
    this.pushActualRow(rowID);
    this.functionsControllerService.getFunction(this.structure[this.index]['child'][id], this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].groupedDataInfo[this.indexTable][rowID]);
  }

  pushActualRow(rowID, isFirst = true) {
    if(isFirst) {
      this.genericService.tablesActualRows = [];
      this.oldValue = -1;
     }
    this.genericService.tablesActualRows.push({"id_functional_area": this.structure[this.index]['id_functional_area'], "rowID": rowID, "indexTable": this.indexTable});
    this.actualRow = null;
  }

  public isHighlightRow(oldBdField, rowID, row) {
    if(this.genericService.tablesActualRows && this.genericService.tablesActualRows.length && this.genericService.tablesActualRows.length > 0 && (row['isHighlightRow'] === undefined || row['lastTablesActualRows'] === undefined || this.genericService.tablesActualRows != row['lastTablesActualRows'])) {
      row['lastTablesActualRows'] = this.genericService.tablesActualRows;
      for(let x in this.genericService.tablesActualRows) {
        if(this.genericService.tablesActualRows[x]['id_functional_area'] == this.structure[this.index]['id_functional_area'] && rowID == this.genericService.tablesActualRows[x]['rowID']) {
          row['isHighlightRow'] = true;
          return true;
        }
      }
      row['isHighlightRow'] = oldBdField == this.oldValue;
      return oldBdField == this.oldValue;
    } else {
      return row['isHighlightRow'];
    }
  }

  private isValidDate(str: string) {
    // Regular expression to check if string is valid date
    const regexExp = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/gi;
    return regexExp.test(str);
  }

  applySort() {
    if(this.lastSort) {
      setTimeout(() => { // Need to put timeout because I need to wait to the table to be loaded, at least de paginator if not will initialize the table with the first page always (and will say the correct page in the paginator but will show the first page)
        this.dataSource.sort.active = this.lastSort.active;
        this.dataSource.sort.direction = this.lastSort.direction;
        this.dataSource.sort.sortChange.emit(this.lastSort);
      });
    }
  }

  calculateHeights() {
    this.genericService.heightTableChange = false;
    setTimeout(() => {
      this.getHeightV = this.getHeight();
      this.getHeightTable2V = this.getHeightTable2();
      this.getHeightTableV = this.getHeightTable();
    });
  }

  iconClick(id: any, event) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    this.functionsControllerService.getFunction(this.structure[this.index]['child'][id]);
  }

  public getHeight() {
    let boxTable0 = document.getElementsByClassName('mat-elevation-z3-' + this.structure[this.index]['id_functional_area'] + '-' + this.indexTable)[0];
    let boxTableFinal = 0;
    if(boxTable0 !== undefined) {
      let boxTable = boxTable0.getBoundingClientRect();
      boxTableFinal = boxTable.height;
    }

    let boxNoRows0 = document.getElementsByClassName('noRowsGeneric-' + this.structure[this.index]['id_functional_area'] + '-' + this.indexTable)[0];
    let boxNoRowsFinal = 0;
    if(boxNoRows0 !== undefined) {
      let boxNoRows = boxNoRows0.getBoundingClientRect();
      boxNoRowsFinal = boxNoRows.height;
    }
    
    boxTableFinal = boxTableFinal + boxNoRowsFinal;
    return boxTableFinal + 18;
  }

  public getHeightTable() {
    return this.getHeightFilter() + this.getHeightBarTools();
  }

  public getHeightTable2() {
    let box0 = document.getElementsByClassName('mat-dialog-content')[0];
    let boxFinal = 0;
    if(box0 !== undefined) {
      let box = box0.getBoundingClientRect();
      boxFinal = box.height;
    }
    boxFinal = boxFinal - this.getHeightFilter();
    let fullHeight = +this.getHeight() - this.getHeightFilter();

    if(fullHeight > boxFinal) boxFinal = fullHeight;
    return boxFinal;
  }

  public getHeightBarTools() {
    return 0;
    let barTools0 = document.getElementsByClassName('bar-tools')[0];
    let barToolsFinal = 0;
    if(barTools0 !== undefined) {
      let barTools = barTools0.getBoundingClientRect();
      barToolsFinal = barTools.height;
    }
    return barToolsFinal;
  }

  public getHeightFilter() {
    let boxFilter0 = document.getElementsByClassName('bottom-table-generic-' + this.structure[this.index]['id_functional_area'])[0];
    let boxFilterFinal = 0;
    if(boxFilter0 !== undefined) {
      let boxFilter = boxFilter0.getBoundingClientRect();
      boxFilterFinal = boxFilter.height;
    }
    let boxFilter0Mobile = document.getElementsByClassName('bottom-table-generic-mb')[0];
    if(boxFilter0Mobile !== undefined) {
      let boxFilter = boxFilter0Mobile.getBoundingClientRect();
      boxFilterFinal = boxFilter.height;
    }
    return boxFilterFinal;
  }
  
  calculateHeightsInit() {
    if(!this.structure[this.index]['calculateHeightsInit']) {
      this.structure[this.index]['calculateHeightsInit'] = true;
      this.calculateHeights();
    }
    return true;
  }

  public appearProfilePermission(idFa, rowKey) {
    let e = "profile-permissions-appear-" + idFa;
    if(rowKey && rowKey[e] !== undefined && rowKey[e] == 0) return false;
    return true;
  }

  public expansionChange(row) {
    this.functionsControllerService.getFunction(this.structure[this.index], row, 47)
  }
  
  public expandedColumn() {
    if(this.genericService.heightTableChange) this.calculateHeights();
    return this.expandedCol !== null;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(data) {
    if (this.isAllSelected()) {
      this.selection.clear(); // TODO: solo eliminar los valores que llegan en data del selection
      return;
    }

    this.selection.select(...data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(data, row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  checkSelectionMultiple(i, row) {
    row['rowID'] = i;
    this.selection.toggle(row);
    return true;
  }

  onPageChange(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.saveFilter();
  }

  onColumnSelectSort(column: string, data) {
    const currentSort = data.sort;
    let sortState: Sort = { active: '', direction: '' };
    if(currentSort && currentSort.active) {
      const isAsc = currentSort.active === column && currentSort.direction === 'asc';
      const direction = isAsc ? 'asc' : 'desc';
      let newSortState: Sort = { active: column, direction: direction };
      if(JSON.stringify(this.lastSort) != JSON.stringify(newSortState)) sortState = newSortState;
    }
    this.lastSort = sortState;
    this.saveFilter();
  }
  
  checkIfParamExists() {
    return this.genericService.checkIfParamExists(this.actual_id_functional_area, this.structure[this.index]);
  }

  getNumPages() {
      // Obtén el número total de elementos después de aplicar cualquier filtro
      const elementosFiltrados = this.dataSource.filteredData.length;

      // Obtén el tamaño de la página de la configuración del paginador
      const elementosPorPagina = this.dataSource.paginator?.pageSize || 1;

      // Calcula el número total de páginas
      return Math.ceil(elementosFiltrados / elementosPorPagina);
  }

  // Método para obtener el número de la página actual
  getCurrentPage(): number {
    // Asegúrate de que el paginador esté disponible
    if (this.paginator) {
      return this.paginator.pageIndex;
    }
    return 0; // Valor predeterminado si el paginador no está disponible
  }

  // Método para obtener el número de la página actual
  getCurrentPageSize(): number {
    // Asegúrate de que el paginador esté disponible
    if (this.paginator) {
      return this.paginator.pageSize;
    }
    return 0; // Valor predeterminado si el paginador no está disponible
  }

  saveFilter() {

  }

  initColumnsToExport() {
    const copy = this.genericService.cloneVariable(this.displayedColumns);
  
    this.columnsToExport = copy.filter(item => {
      const [idStr, fieldName] = item.split('-');
  
      if (!fieldName) return false;
  
      const idFa = idStr ? this.genericService.findElementWithId(idStr, false, false, true) : null;
  
      if (!idFa) return false;
      if (idFa.type === 'image' || idFa.type === 'expansion') return false;
      if (!idFa.id_db || !idFa.bd_table || !idFa.bd_field) return false;
      if (idFa.id_db === "" || idFa.bd_table === "" || idFa.bd_field === "") return false;
  
      return true;
    });
  }  

  exportTableAsExcel() {
    this.genericService.exportTableAsExcel(this.dataSource.filteredData, this.columnsToExport)
  }

  checkArray(element: any) {
    return Array.isArray(element) && element.length > 1;
  }
}