import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { GenericService } from 'app/services/generic/generic.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TextComponent implements OnInit {

  @Input() structure = null;
  @Input() index : number;
  @Input() param : any = null;


  // public textType: string;
  public idTable = null;
  public idRow = null;
  public idTableIndex = null;

  public functionFinish = {};


  constructor(public sanitizer: DomSanitizer,
              public authService: AuthService,
              private functionsControllerService: FunctionsControllerService,
              public genericService: GenericService) { }

  ngOnInit(): void {    
    // this.textType = this.structure[this.index]['type'] !== '' ? this.structure[this.index]['type'] : 'p';
    if(this.structure[this.index]['type'] == 'iframe' && !this.structure[this.index]['sanitizer'] && !this.structure[this.index]['creatingMode']) {
      this.structure[this.index]['url'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.structure[this.index]['url'])
      this.structure[this.index]['sanitizer'] = true;
    }

    this.functionFinish[this.index] = true;

    if(this.param !== null) {
      let respPar = this.genericService.fillTableDataFromParam(this.param, this.idTable, this.idRow, this.idTableIndex);
      this.idTable = respPar['idTable'];
      this.idRow = respPar['idRow'];
      this.idTableIndex = respPar['idTableIndex'];
    }
    if(this.structure[this.index]['type'] == "img" && this.structure[this.index]['url'] && this.structure[this.index]['url'] !== null && this.structure[this.index]['url'] !== "" && this.structure[this.index]['url'].includes("/empresas/") && !this.structure[this.index]['creatingMode']) {
      this.structure[this.index]['url'] = this.structure[this.index]['url'] + "?" + new Date().getTime();
    }
  }

  onClickEvent(event) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if (this.structure[this.index]["id_function"]) {
      if(this.idTable == null && this.idRow == null) {
        let data = {done: false};
        this.functionFinish[this.index] = false;
        this.functionsControllerService.getFunction(this.structure[this.index], this.param, data);
        this.functionFinish[this.index] = data.done;
      } else {
        this.genericService.tablesActualRows.push({ "id_functional_area": this.idTable, "rowID": this.idRow, "index": this.idTableIndex });
        this.functionsControllerService.getFunction(this.structure[this.index], this.genericService.tablesInformation[this.idTable]['groupedDataInfo'][this.idTableIndex][this.idRow], this.idTable)
      }
    }
  }

  scrollTo(id:string){
    let el = document.getElementById(id.slice(1));
    this.genericService.scroll(id.slice(1));
  }
}
